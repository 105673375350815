import React from "react";
import { Button } from "react-bootstrap";
import styled from 'styled-components';

// Icons
import { IconContext } from "react-icons";
import { FaTimes } from "react-icons/fa";

const CardWrapper = styled.div`
  max-height: 100%;
`;

const DialogCard = (props) => (
  <IconContext.Provider value={{ size: "1.5em" }}>
    <CardWrapper className="card">
      <div className={`card-body ${props.className ? props.className : ''}`}>
        <div className="main">
          <div className={`header row align-items-center ${props.hideHeander ? 'd-none' : ''}`}>
            <div className="col">
              <h1 className="product-title">{props.title}</h1>
            </div>
            {!props.hiddenCloseButton &&(
              <Button
                variant="outline-light"
                size="sm"
                onClick={props.onClose}
                className="button-close"
              >
                <FaTimes />
              </Button>  
            )}
          </div>
          {props.children}
        </div>
      </div>
    </CardWrapper>
  </IconContext.Provider>
);

export default DialogCard;
