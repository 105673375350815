import React, { useState, useEffect } from "react";

// Context
import ManageWindowProvider from "./store/ManageWindowProvider";
import NotificationProvider from "./store/notificationProvider";
import CartProvider from "./store/CartProvider";

import {getLocalStorage} from "./helpers/storage";

//Components
import SplashScreen from "./components/SplashScreen/SplashScreen";
import App from "./NewApp";

const Router = (props) => {
  const [loadingApp, setLoadingApp] = useState(true);

  useEffect(() => {
    let redirectPage = getLocalStorage('redirect-page')
    if (redirectPage && redirectPage !== "") {
        setLoadingApp(false);
    }
  }, []);

  return (
    <React.Fragment>
      {loadingApp && <SplashScreen setIsLoading={setLoadingApp} />}

      {!loadingApp && (
        <ManageWindowProvider>
          <NotificationProvider>
            <CartProvider>
              <App />
            </CartProvider>
          </NotificationProvider>
        </ManageWindowProvider>
      )}
    </React.Fragment>
  );
};

export default Router;
