import React, { useState, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { cpf } from "cpf-cnpj-validator";

import { encryptText, decryptText, cpfMask } from "../../../helpers/string";
import { formatCurrency } from "../../../helpers/numbers";
import { getQuotaTravelerByCpf } from "../../../services/traveler";

// Components
import DialogWrapper from "../../UI/DialogBox/DialogWrapper";
import DialogCard from "../../UI/DialogBox/DialogCard";
import DialogCardBody from "../../UI/DialogBox/DialogCardBody";
import DialogTagWrapper from "../../UI/DialogBox/DialogTagWrapper";
import CallButton from "../../UI/Buttons/CallButton";

// Icons
import { IconContext } from "react-icons";
import useInput from "../../../hooks/use-input";
import { BsSearch as CartIcon } from "react-icons/bs";

const localStorageKey = "entry-quota";

const Wrapper = styled.div`
  .card-body .main .body {
    padding-left: 0.5rem;
    margin-top: 1rem;
    min-height: 7rem;
  }

  .form-search-quota {
    display: flex;

    .form-control {
      height: 38px;
      border: 1px solid #ced4da;
      background-color: #fff;

      &:focus {
        border: 1px solid #fff;
        box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
      }
    }

    .form-floating {
      width: 40vw;

      span {
        font-size: 10px;
        color: #252525;
      }
    }

    .form-floating > label {
      top: -5px;
    }

    button {
      height: 2.5rem;
      margin-left: 10px;
      width: 2.6rem;
      border-radius: unset;
    }
  }

  .invalid {
    background-color: #ff4500;
    background-clip: padding-box;
    border: 2px solid #ff4500;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .form-control:focus {
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(255 99 71 / 25%);
    }
  }

  .quota-content {
    display: flex;
    flex-direction: row;
    
    @media (max-width: 720px) {
      display: inline-flex;
      flex-direction: column;
      padding: 0;
      
    }
  }

  .quote-available {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-weight: 500;
    line-height: 1;

    h6,
    div {
      margin: 0;
    }

    .currency {
      margin-right: 4px;
      font-size: 14px;
    }

  }

  .products-quote-available {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 5rem;

    h6 {
      margin: 0;
    }

    @media (max-width: 720px) {
      margin: 0;

    }
  }

  
  @media (hover: none) {
    .btn-outline-light {
      color: #f8f9fa;
      border-color: #f8f9fa;
      background-color: transparent;
    }
  }
`
;

const QuotaBrazilian = (props) => {
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [quotaAvailable, setQuotaAvailable] = useState(0);
  const [beveragesAvailable, setBeveragesAvailable] = useState(0);
  const [cigarrettesAvailable, setCigarrettesAvailable] = useState(0);
  const [cigarsAvailable, setCigarsAvailable] = useState(0);
  const [smokeAvailable, setSmokeAvailable] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let classes = "";

  const isValid = (value) => value.length > 0 && cpf.isValid(value.replace(/\D/g, ""));
  const {
    value: cpfInput,
    setInput: setCpfInput,
    isInvalid,
    isTouched,
    onChangeHandler: cpfInputChangeHandler,
    onBlurHandler: cpfInputBlurHandler,
    onReset: onResetCpfInput
  } = useInput(isValid)

  const cpfKeyValue =
    localStorage.getItem(localStorageKey) &&
    decryptText(localStorage.getItem(localStorageKey));
  
  const unloadUser = () => {
    localStorage.removeItem(localStorageKey);
    setIsUserLoaded(false);
    onResetCpfInput();
  };

  const searchTravelerQuota = useCallback(async () => {
    if (isInvalid) return;
    if (cpfInput.length === 0) return;

    let qtyBeverages, qtyCigarrettes, qtyCigars, qtySmoke = 0;

    setIsLoading(true);
    setRequestError("");

    const res = await getQuotaTravelerByCpf(cpfInput);

    if (res.status !== 200) {
      const errorMsg =
        "Estamos com problemas ao realizar a consulta. Favor tentar novamente mais tarde.";
      setRequestError(errorMsg);
      setIsLoading(false);
      return;
    }

    const data = res.data;
    
    if (!cpfKeyValue)
      localStorage.setItem(localStorageKey, encryptText(cpfInput));

    data["saldoCotaProduto"].forEach((product) => {
      if (parseInt(product["codigoProduto"]) === 1) {
        qtyBeverages = parseInt(product["quantidade"]);
      }
      
      if (parseInt(product["codigoProduto"]) === 5) {
        qtyCigarrettes = parseInt(product["quantidade"]);
      }
      
      if (parseInt(product["codigoProduto"]) === 6) {
        qtyCigars = parseInt(product["quantidade"]);
      }
      
      if (parseInt(product["codigoProduto"]) === 7) {
        qtySmoke = parseInt(product["quantidade"]);
      }
    });
    
    setQuotaAvailable(parseFloat(data["valorSaldoCota"]));
    setBeveragesAvailable(qtyBeverages);
    setCigarrettesAvailable(qtyCigarrettes);
    setCigarsAvailable(qtyCigars);
    setSmokeAvailable(qtySmoke);
    setIsLoading(false);
    setRequestError("");
    setIsUserLoaded(true);
  }, [isInvalid, cpfInput, cpfKeyValue]);

  useEffect(() => {
    if (!cpfKeyValue) {
      setIsUserLoaded(false);
      return;
    }

    setCpfInput(cpfKeyValue);
    searchTravelerQuota();
  }, [cpfKeyValue, searchTravelerQuota, setCpfInput]);

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const CartButtonStyled = styled(CallButton)`
      &.bump {
      }
    `;

  let ShowedButton = (
    <IconContext.Provider value={{ size: "1.3em" }}>
      <CartButtonStyled
        size="2.5rem"
        variant="outline-light"
        onClick={searchTravelerQuota}
        disabled={(isLoading || !isTouched || isInvalid) && true}
        className={"bump"} 
      >
      <CartIcon />
      </CartButtonStyled>
    </IconContext.Provider>
  );

  if (isUserLoaded) {
    ShowedButton = (
      <IconContext.Provider value={{ size: "1.3em" }}>
        <CartButtonStyled
          size="2.5rem"
          variant="outline-light"
          onClick={unloadUser}
          className={"bump"} 
        >
        <CartIcon />
        </CartButtonStyled>
      </IconContext.Provider>
    );
  }

  if (isInvalid) {
    classes = "invalid";
  }

  return (
    <IconContext.Provider value={{ size: "1.7em" }}>
      <Wrapper className={classes}>
        <DialogWrapper>
          <DialogCard onClose={props.onClose} hiddenCloseButton="true">
            <DialogCardBody>
              <div className="row pb-3">
                <p><b>Consulta de cota</b></p>
              </div>
              <div className="row">
                <div className="col">
                  <Form onSubmit={formSubmit}>
                    <Form.Group controlId="floatingInputCPF2">
                      <Form.Label>CPF <span className="text-primary">*</span></Form.Label>
                      <div className="row">
                        <div className="col">
                          <Form.Control
                            type="text"
                            value={cpfMask(cpfInput)}
                            placeholder="###.###.###-##"
                            inputMode="numeric"
                            onChange={cpfInputChangeHandler}
                            onBlur={cpfInputBlurHandler}
                            disabled={(isUserLoaded || isLoading) && true}
                            autoFocus={isUserLoaded ? false : true}
                          />
                          {isLoading && <span>Consultando...</span>}
                          {requestError && requestError.length > 0 && (
                            <span>{requestError}</span>
                          )}         
                        </div>
                        <div className="col-2">
                          {ShowedButton}
                        </div>
                      </div>
                    </Form.Group>   
                  </Form>
                </div>
                
              </div>
              {isUserLoaded && (
                <div className="quota-content">
                  <div className="quote-available mt-3">
                    <h6>Saldo disponível</h6>
                    <div className="group-currency">
                      <span className="currency">$</span>
                      <span className="value">
                        {formatCurrency(quotaAvailable)}
                      </span>
                    </div>
                  </div>
                  <div className="products-quote-available mt-3">
                    <h6>Bebidas alcoólicas (l)</h6>
                    <span className="value">
                      {beveragesAvailable && `${beveragesAvailable}`}
                    </span>
                  </div>

                  <div className="products-quote-available mt-3">
                    <h6>Maços de cigarro (un)</h6>
                    <span className="value">
                      {cigarrettesAvailable && `${cigarrettesAvailable}`}
                    </span>
                  </div>

                  <div className="products-quote-available mt-3">
                    <h6>Charutos e cigarrilhas (un)</h6>
                    <span className="value">
                      {cigarsAvailable && `${cigarsAvailable}`}
                    </span>
                  </div>

                  <div className="products-quote-available mt-3">
                    <h6>Fumo preparado para cachimbo (g)</h6>
                    <span className="value">
                      {smokeAvailable && `${smokeAvailable}`}
                    </span>
                  </div>
                </div>
              )}
            </DialogCardBody>
            <DialogTagWrapper>
              <button className="btn btn-outline-secondary" onClick={props.onClose}>
                  Voltar
              </button>              
            </DialogTagWrapper>
          </DialogCard>
        </DialogWrapper>
      </Wrapper>
    </IconContext.Provider>
  );
};

export default QuotaBrazilian;
