import { OPEN_CLOSE_CART, OPEN_CLOSE_PRICE_DIALOG, OPEN_CLOSE_QUOTA, OPEN_CLOSE_CHECKOUT, OPEN_CLOSE_CHECKOUT_CONFIRMATION, OPEN_CLOSE_BARCODE_INPUT} from '../actions/manageWindows'

export const initialState ={
    showPriceDialog: false,
    showQuota: false,
    showCart: false,
    showCheckout: false,
    showCheckoutConfirmation: false,
    showBarcodeInput: false
}

const manageWindowsReducer = (state, { type, payload }) => {
    switch(type) {
        case OPEN_CLOSE_PRICE_DIALOG:
            return {
                ...initialState,
                showPriceDialog: payload,
            }
        case OPEN_CLOSE_QUOTA:
            return {
                ...initialState,
                showQuota: payload,
            }
        case OPEN_CLOSE_CART:
            return {
                ...initialState,
                showCart: payload,
            }
        case OPEN_CLOSE_CHECKOUT:
            return {
                ...initialState,
                showCheckout: payload,
            }
        case OPEN_CLOSE_CHECKOUT_CONFIRMATION:
            return {
                ...initialState,
                showCheckoutConfirmation: payload,
            }
            case OPEN_CLOSE_BARCODE_INPUT:
                return {
                    ...initialState,
                    showBarcodeInput: payload,
                }
            default:
            return initialState;
    }
}

export default manageWindowsReducer;