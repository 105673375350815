import styled from "styled-components";

const OrderBox = styled.div`
  .btn-outline-light {
    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  @media only screen {
    button:focus {
      box-shadow: none !important;
    }
  }
`;

export default OrderBox;