import React, { useState } from "react";

//UI
import OrderBox from "../UI/OrderBox";
import Checkout from "./Checkout"
import Confirmation from "./Confirmation";

const Wrapper = (props) => {
  const [orderId, setOrderId] = useState(1);
  const backButtonCheckout = () => props.onOpenCart();

  const showCheckoutConfirmation = (orderId) => {
    setOrderId(orderId)
    props.onOpenCheckoutConfirmation();
  }

  const closeCheckoutConfirmation = () => {
    props.onCloseCheckoutConfirmation();
    props.onClearCart();
  }

  return (
    <OrderBox>
      {props.showCheckout && (
        <Checkout
          items={props.items}
          onConfirmation={showCheckoutConfirmation}
          backButton={backButtonCheckout}
        />
      )}

      {(props.showCheckoutConfirmation && orderId) && (
        <Confirmation
          orderId={orderId}
          items={props.items}
          onClose={closeCheckoutConfirmation}
          />
      )}
    </OrderBox>
  );
};

export default Wrapper;
