import axios from 'axios';
import { get_env } from "../helpers/env";

export const getProductPrecifica = async (code) => {
    const API = axios.create({
        baseURL: get_env('REACT_APP_BASE_URL_ORDER_API', ''),
        headers: {
          "content-type": "application/json"
        }
    })          

    const response = await API.get(`/precifica/${code}`);    
    // const response = await API.get(`/precifica/6460227`);    
    return response
}