import { Button } from "react-bootstrap";
import styled from "styled-components";

const DialogButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: unset;
  font-weight: 400;
  min-height: 41px;
  min-width: 139px;

  // span {
  //   font-size: 8px;
  //   text-transform: uppercase;
  // }

  &.red {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #FA0202;
    border-radius: 30px;

    &:focus {
      background-color: #FFFFFF;
      border-color: #FFFFFF;
      box-shadow: 0 0 0 0.25rem rgb(129 161 35 / 50%);
    }
  }

  &.orange {
    background-color: #FFA500;
    border-color: #FFA500;
    font-weight: bold;
    color: #fff;

    &:focus {
      background-color: #ffc14e;
      border-color: #FFA500;
      box-shadow: 0 0 0 0.25rem rgb(129 161 35 / 50%);
    }

    &:hover {
      background-color: #ffc14e;
      border-color: #FFA500;
    }
  }

  &.red-margin-right {
    background-color: #e33338;
    border-color: #e33338;
    font-weight: bold;
    color: #fff;
    margin-right: 10px;

    &:focus {
      background-color: #ef4d51;
      border-color: #ef4d51;
      box-shadow: 0 0 0 0.25rem rgb(129 161 35 / 50%);
    }

    &:hover {
      background-color: #ef4d51;
      border-color: #ef4d51;
    }
  }

  @media only screen and (max-width: 768px) {
    &:focus {
      box-shadow: unset !important;
    }
  }
`;

export default DialogButton;