import React, {useContext } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

// Context 
import ManageWindowsContext from "../store/manageWindowsContext";

// Components
import QuotaBrazilian from "./actions/QuotaBrazilian/QuotaBrazilian";
import CallButton from "./UI/Buttons/CallButton";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
`;

const CallActionButton = (props) => {
  const manageWindowCtx = useContext(ManageWindowsContext);
  const isOpenAction = manageWindowCtx.showQuota;

  let BoxAction = null;

  const actions = [
    {
      direction: "top",
      action: "traveler-brazilian-quota",
      icon: "",
      "background-color": "",
      color: "",
      title: "Consulta de cota",
      description: "Busca da cota do viajante brasileiro",
    },
  ];

  const openCloseActions = () => manageWindowCtx.openCloseQuota(true);

  const closeAction = () => manageWindowCtx.openCloseQuota(false);

  if (isOpenAction) {
    const Action = QuotaBrazilian;
    const title = actions[0].title;

    BoxAction = ReactDOM.createPortal(
      <Action title={title} onClose={closeAction} />,
      document.querySelector("#modal-root")
    );
  }

  return (
    <React.Fragment>
      {!isOpenAction && (
        <ButtonWrapper>
          <CallButton size="2.9rem" onClick={openCloseActions}>
            Cota
          </CallButton>
        </ButtonWrapper>
      )}
      {isOpenAction && BoxAction}
    </React.Fragment>
  );
};

export default CallActionButton;
