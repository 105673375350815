import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

// Assets
import { ReactComponent as AddCartIcon }  from "../../assets/images/add-cart-products.svg";

const Modal = ({ show, onClose, hideImg = true, title = null, description = null, btnOne, btnTwo, onclickOne, onclickTwo ,classBtnOne, classBtnTwo }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal>
        <div className="text-center mb-3" hidden={hideImg}>
          <AddCartIcon />
        </div>
        <div className="text-center">
          <StyledModalTitle>{title}</StyledModalTitle>
          <StyledModalDescription>{description}</StyledModalDescription>
        </div>
        <div className="mb-2">
          <button className={classBtnOne} onClick={onclickOne}>
              {btnOne}
          </button>
        </div>
        <div>
          <button className={classBtnTwo} onClick={onclickTwo}>
              {btnTwo}
          </button>
        </div>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root")
    );
  } else {
    return null;
  }
};

const StyledModalBody = styled.div`
  padding-top: 10px;
`;

const StyledModalTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
`;

const StyledModalDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 15px;
`;

const StyledModal = styled.div`
  width: 100%;
  height: auto;
  margin: 0 20px;
  border-radius: 15px;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;
const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525ed;
  z-index: 1000!important;
`;

export default Modal;