import React from "react";

import './ReadingLine.css'

const ReadingLine = () => {
    return (
        <>
            <div className="top-corner">
                <div className="top-left-corner"></div>
                <div className="space-center"></div>
                <div className="top-right-corner"></div>
            </div>
            <div className="reading-line"></div>
            <div className="bottom-corner">
                <div className="bottom-left-corner"></div>
                <div className="space-center"></div>
                <div className="bottom-right-corner"></div>
            </div>
        </>
    )
}

export default ReadingLine;