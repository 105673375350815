import React, { useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

// Components
import DialogWrapper from "../UI/DialogBox/DialogWrapper";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import { getProduct } from "../../services/product";
import CallButton from "../UI/Buttons/CallButton";

// Icons
import { IconContext } from "react-icons";
import { BsSearch as CartIcon } from "react-icons/bs";

const Wrapper = styled.div`
  .card-body .main .body {
    padding-left: 0.5rem;
    margin-top: 1rem;
  }

  .card .card-body {
    background-color: transparent;

    .main {
      margin-bottom: 20px;

      .header {
        .button-close {
          border: none;
          font-size: 12px;
        }
      }
    }
  }

  .form-barcode-input {
    
    input {
      display: flex;
      height: 2.5rem;
      color: #252525;
      width: 100%;
      
      &:focus {
        border: 1px solid #fff;
     }
  }
  .invalid {
    background-color: #ff4500;
    background-clip: padding-box;
    border: 2px solid #ff4500;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  }
  span {
    color: white;
  }
`;

const BarcodeInput = (props) => {
  const [barcodeValue, setBarcodeValue] = useState("");
  const [requestError, setRequestError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

    const CartButtonStyled = styled(CallButton)`
      &.bump {
      }
    `;

  const searchProductByEan = useCallback(async () => {
    if (barcodeValue.length === 0) return;
    setBarcodeValue(barcodeValue);
    setIsLoading(true);

    const res = await getProduct(barcodeValue);
    setIsLoading(false);
    setRequestError("");
    if (res.status !== 200 || res.data.error) {
      const errorMsg = t("Código de barras inválido.")
      setRequestError(errorMsg);
      return;
    }

    props.getProductByEan({text: barcodeValue});
    props.onCloseBarcodeInput();
    setRequestError("");
  },[barcodeValue, props, t])

  const onChangeHandle = (e) => {
    setBarcodeValue(e.target.value);
    setRequestError("");
  };

  return (
    <IconContext.Provider value={{ size: "1.7em" }}>
      <Wrapper>
        <DialogWrapper>
          <DialogCard onClose={props.onCloseBarcodeInput} className="small">
            <DialogCardBody>
              <div className="row align-items-end">
                <div className="col">
                  <Form onSubmit={formSubmit} className="form-barcode-input">
                      <label className="text-white mb-2">{t('Código de barras do produto')}</label>
                      <input
                        autoFocus
                        id="barcode" 
                        type="text" 
                        name="barcode" 
                        value={barcodeValue}
                        onChange={onChangeHandle}
                        placeholder={t("Informe o código do produto")}
                      ></input>
                  </Form>
                </div>
                <div className="col-auto">
                  <IconContext.Provider value={{ size: "1.3em" }}>
                    <CartButtonStyled
                      size="2.5rem"
                      variant="outline-light"
                      onClick={searchProductByEan}
                      disabled={isLoading}
                      className={"bump"}
                    >
                    <CartIcon />
                    </CartButtonStyled>
                  </IconContext.Provider>
                </div>
              </div>
              <div>
                {isLoading && <span className="text-white">{t('Consultando...')}</span>}
                {requestError && requestError.length > 0 && <span>{requestError}</span>}
              </div>
            </DialogCardBody>
          </DialogCard>
        </DialogWrapper>
      </Wrapper>
    </IconContext.Provider>
  );
};

export default BarcodeInput;