import React, { useReducer } from "react";

import NotificationContext from "./notificationContext";
import notificationReducer, { initialState } from "./reducer/notification";
import { addNotification, removeNotification } from "./actions/notification";

const NotificationProvider = (props) => {
  const [state, dispatchAction] = useReducer(notificationReducer, initialState);

  const addNotificationHandler = (notification) =>
    dispatchAction(addNotification(notification));

  const removeNotificationHandler = (key) =>
    dispatchAction(removeNotification(key));

  const notificationContext = {
    notifications: state.notifications,
    addNotification: addNotificationHandler,
    removeNotification: removeNotificationHandler
  };

  return (
    <NotificationContext.Provider value={notificationContext}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
