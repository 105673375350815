import styled from "styled-components";

export const Video = styled.div`
  video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;

    canvas {
      display: none;
    }
  }
`
export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      width: 100%;
      background-color: #FFFFFF;
      margin-top: 0 auto;
      text-align: center;
      padding: 10px;
      font-size: 14px;
      line-height: 24px;
      
      span {
        color: #252525;
      }

      @media (max-width: 575.98px) {

        span {
          font-size: 12px
        }
      }
    }

    :before {
      content: "";
      background: black;
      width: 100%;
      height: calc(50% - 143px);
      min-height: 122px;
      position: absolute;
      top: 44px;
      left: 0;
      z-index: 0;
      opacity: 0.7;
    }
    
    :after {
      content: "";
      background: black;
      width: 100%;
      height: calc(50% - 143px);
      min-height: 122px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      opacity: 0.7;
    }
`

export const ScanMarker = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Codebutton = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    bottom: 20px;
    background-color: transparent;
    
    .label-button {
      text-align: center;
      padding: 10px;
      
      span {
        color: #fff;
      }
    }

    .group-button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    .group-button button {
      margin: 10px;
    }
`;
