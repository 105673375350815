const usFlagSvg = (
  <svg
    className="us-flag"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5689 20.6897H0.431054C0.193017 20.6897 0 20.4966 0 20.2586V4.7414C0 4.50337 0.193017 4.31035 0.431054 4.31035H24.5689C24.807 4.31035 25 4.50337 25 4.7414V20.2586C25 20.4967 24.8069 20.6897 24.5689 20.6897Z"
      fill="#F5F5F5"
    />
    <path
      d="M25 5.57036H0V4.7414C0 4.50337 0.193017 4.31035 0.431054 4.31035H24.5689C24.807 4.31035 25 4.50337 25 4.7414L25 5.57036Z"
      fill="#FF4B55"
    />
    <path d="M25 9.35013H0V10.6101H25V9.35013Z" fill="#FF4B55" />
    <path d="M25 6.83011H0V8.09007H25V6.83011Z" fill="#FF4B55" />
    <path
      d="M25 13.13H0.431054C0.193017 13.13 0 12.937 0 12.6989V11.87H25V13.13Z"
      fill="#FF4B55"
    />
    <path d="M25 16.9102H0V18.1701H25V16.9102Z" fill="#FF4B55" />
    <path
      d="M24.5689 20.6897H0.431054C0.193017 20.6897 0 20.4966 0 20.2586V19.4297H25V20.2587C25 20.4967 24.8069 20.6897 24.5689 20.6897Z"
      fill="#FF4B55"
    />
    <path d="M25 14.3902H0V15.6501H25V14.3902Z" fill="#FF4B55" />
    <path
      d="M11.2069 4.31039H0.431054C0.193017 4.31039 0 4.50336 0 4.7414V12.6989C0 12.9369 0.193017 13.13 0.431054 13.13H11.2069C11.4449 13.13 11.638 12.9369 11.638 12.6989V4.7414C11.6379 4.50336 11.4449 4.31039 11.2069 4.31039Z"
      fill="#41479B"
    />
    <path
      d="M1.2104 5.29982L1.30581 5.58595L1.60742 5.58825C1.64648 5.58859 1.66264 5.6384 1.63125 5.66159L1.38862 5.84079L1.47964 6.12834C1.49145 6.16554 1.44902 6.1964 1.41724 6.17365L1.17187 5.99831L0.926514 6.1737C0.894727 6.1964 0.852393 6.16559 0.864112 6.12839L0.955127 5.84084L0.712501 5.66164C0.681104 5.6384 0.697266 5.58864 0.736329 5.5883L1.03794 5.586L1.13335 5.29987C1.14565 5.26276 1.198 5.26276 1.2104 5.29982Z"
      fill="#F5F5F5"
    />
    <path
      d="M1.2104 6.79649L1.30581 7.08262L1.60742 7.08492C1.64648 7.08526 1.66264 7.13506 1.63125 7.15826L1.38862 7.33745L1.47964 7.625C1.49145 7.66221 1.44902 7.69307 1.41724 7.67031L1.17187 7.49497L0.926514 7.67036C0.894727 7.69307 0.852393 7.66226 0.864112 7.62505L0.955127 7.3375L0.712501 7.15831C0.681104 7.13506 0.697266 7.08531 0.736329 7.08497L1.03794 7.08267L1.13335 6.79654C1.14565 6.75948 1.198 6.75948 1.2104 6.79649Z"
      fill="#F5F5F5"
    />
    <path
      d="M1.2104 8.29326L1.30581 8.57939L1.60742 8.58168C1.64648 8.58203 1.66264 8.63183 1.63125 8.65502L1.38862 8.83422L1.47964 9.12177C1.49145 9.15898 1.44902 9.18984 1.41724 9.16709L1.17187 8.99174L0.926514 9.16713C0.894727 9.18984 0.852393 9.15903 0.864112 9.12182L0.955127 8.83427L0.712501 8.65507C0.681104 8.63183 0.697266 8.58207 0.736329 8.58173L1.03794 8.57944L1.13335 8.2933C1.14565 8.25624 1.198 8.25624 1.2104 8.29326Z"
      fill="#F5F5F5"
    />
    <path
      d="M1.2104 9.79007L1.30581 10.0762L1.60742 10.0785C1.64648 10.0788 1.66264 10.1286 1.63125 10.1518L1.38862 10.331L1.47964 10.6186C1.49145 10.6558 1.44902 10.6867 1.41724 10.6639L1.17187 10.4886L0.926514 10.6639C0.894727 10.6867 0.852393 10.6558 0.864112 10.6186L0.955127 10.3311L0.712501 10.1519C0.681104 10.1286 0.697266 10.0789 0.736329 10.0785L1.03794 10.0763L1.13335 9.79012C1.14565 9.75301 1.198 9.75301 1.2104 9.79007Z"
      fill="#F5F5F5"
    />
    <path
      d="M1.2104 11.2869L1.30581 11.573L1.60742 11.5753C1.64648 11.5756 1.66264 11.6254 1.63125 11.6486L1.38862 11.8278L1.47964 12.1154C1.49145 12.1526 1.44902 12.1834 1.41724 12.1607L1.17187 11.9853L0.926514 12.1607C0.894727 12.1834 0.852393 12.1526 0.864112 12.1154L0.955127 11.8279L0.712501 11.6487C0.681104 11.6254 0.697266 11.5757 0.736329 11.5753L1.03794 11.573L1.13335 11.2869C1.14565 11.2497 1.198 11.2497 1.2104 11.2869Z"
      fill="#F5F5F5"
    />
    <path
      d="M2.37217 6.03354L2.46758 6.31967L2.76919 6.32197C2.80825 6.32231 2.82441 6.37212 2.79301 6.39531L2.55039 6.57451L2.6414 6.86206C2.65322 6.89926 2.61079 6.93012 2.579 6.90737L2.33364 6.73198L2.08828 6.90737C2.05649 6.93007 2.01416 6.89926 2.02588 6.86206L2.11689 6.57451L1.87427 6.39531C1.84287 6.37207 1.85903 6.32231 1.89809 6.32197L2.19971 6.31967L2.29512 6.03354C2.30737 5.99653 2.35981 5.99653 2.37217 6.03354Z"
      fill="#F5F5F5"
    />
    <path
      d="M2.37217 7.53036L2.46758 7.81649L2.76919 7.81878C2.80825 7.81913 2.82441 7.86893 2.79301 7.89212L2.55039 8.07132L2.6414 8.35887C2.65322 8.39607 2.61079 8.42693 2.579 8.40418L2.33364 8.22879L2.08828 8.40418C2.05649 8.42688 2.01416 8.39612 2.02588 8.35887L2.11689 8.07132L1.87427 7.89212C1.84287 7.86888 1.85903 7.81913 1.89809 7.81878L2.19971 7.81649L2.29512 7.53036C2.30737 7.4933 2.35981 7.4933 2.37217 7.53036Z"
      fill="#F5F5F5"
    />
    <path
      d="M2.37217 9.02714L2.46758 9.31327L2.76919 9.31557C2.80825 9.31591 2.82441 9.36571 2.79301 9.38891L2.55039 9.5681L2.6414 9.85565C2.65322 9.89286 2.61079 9.92372 2.579 9.90096L2.33364 9.72557L2.08828 9.90096C2.05649 9.92367 2.01416 9.89286 2.02588 9.85565L2.11689 9.5681L1.87427 9.38891C1.84287 9.36566 1.85903 9.31591 1.89809 9.31557L2.19971 9.31327L2.29512 9.02714C2.30737 8.99008 2.35981 8.99008 2.37217 9.02714Z"
      fill="#F5F5F5"
    />
    <path
      d="M2.37217 10.5239L2.46758 10.81L2.76919 10.8123C2.80825 10.8127 2.82441 10.8625 2.79301 10.8857L2.55039 11.0649L2.6414 11.3524C2.65322 11.3896 2.61079 11.4205 2.579 11.3977L2.33364 11.2223L2.08828 11.3977C2.05649 11.4204 2.01416 11.3896 2.02588 11.3524L2.11689 11.0649L1.87427 10.8857C1.84287 10.8624 1.85903 10.8127 1.89809 10.8123L2.19971 10.81L2.29512 10.5239C2.30737 10.4869 2.35981 10.4869 2.37217 10.5239Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.53393 5.29971L3.62934 5.58584L3.93095 5.58813C3.97002 5.58848 3.98618 5.63828 3.95478 5.66147L3.71216 5.84067L3.80317 6.12822C3.81499 6.16543 3.77256 6.19629 3.74077 6.17353L3.49541 5.99814L3.25005 6.17353C3.21826 6.19624 3.17593 6.16543 3.18764 6.12822L3.27866 5.84067L3.03603 5.66147C3.00464 5.63823 3.0208 5.58848 3.05986 5.58813L3.36147 5.58584L3.45688 5.29971C3.46919 5.26265 3.52158 5.26265 3.53393 5.29971Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.53393 6.79648L3.62934 7.08261L3.93095 7.08491C3.97002 7.08525 3.98618 7.13505 3.95478 7.15825L3.71216 7.33745L3.80317 7.62499C3.81499 7.6622 3.77256 7.69306 3.74077 7.67031L3.49541 7.49492L3.25005 7.67031C3.21826 7.69301 3.17593 7.6622 3.18764 7.62499L3.27866 7.33745L3.03603 7.15825C3.00464 7.13501 3.0208 7.08525 3.05986 7.08491L3.36147 7.08261L3.45688 6.79648C3.46919 6.75947 3.52158 6.75947 3.53393 6.79648Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.53393 8.29326L3.62934 8.5794L3.93095 8.58169C3.97002 8.58203 3.98618 8.63184 3.95478 8.65503L3.71216 8.83423L3.80317 9.12178C3.81499 9.15899 3.77256 9.18985 3.74077 9.16709L3.49541 8.9917L3.25005 9.16709C3.21826 9.1898 3.17593 9.15899 3.18764 9.12178L3.27866 8.83423L3.03603 8.65503C3.00464 8.63179 3.0208 8.58203 3.05986 8.58169L3.36147 8.5794L3.45688 8.29326C3.46919 8.25625 3.52158 8.25625 3.53393 8.29326Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.53393 9.79008L3.62934 10.0762L3.93095 10.0785C3.97002 10.0788 3.98618 10.1287 3.95478 10.1518L3.71216 10.331L3.80317 10.6186C3.81499 10.6558 3.77256 10.6867 3.74077 10.6639L3.49541 10.4885L3.25005 10.6639C3.21826 10.6866 3.17593 10.6558 3.18764 10.6186L3.27866 10.331L3.03603 10.1518C3.00464 10.1286 3.0208 10.0788 3.05986 10.0785L3.36147 10.0762L3.45688 9.79008C3.46919 9.75302 3.52158 9.75302 3.53393 9.79008Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.53393 11.2868L3.62934 11.573L3.93095 11.5753C3.97002 11.5756 3.98618 11.6254 3.95478 11.6486L3.71216 11.8278L3.80317 12.1154C3.81499 12.1526 3.77256 12.1834 3.74077 12.1607L3.49541 11.9853L3.25005 12.1607C3.21826 12.1834 3.17593 12.1526 3.18764 12.1154L3.27866 11.8278L3.03603 11.6486C3.00464 11.6254 3.0208 11.5756 3.05986 11.5753L3.36147 11.573L3.45688 11.2868C3.46919 11.2497 3.52158 11.2497 3.53393 11.2868Z"
      fill="#F5F5F5"
    />
    <path
      d="M4.69574 6.03354L4.79115 6.31967L5.09277 6.32197C5.13183 6.32231 5.14799 6.37212 5.11659 6.39531L4.87397 6.57451L4.96498 6.86206C4.9768 6.89926 4.93437 6.93012 4.90258 6.90737L4.65722 6.73198L4.41186 6.90737C4.38007 6.93007 4.33774 6.89926 4.34946 6.86206L4.44047 6.57451L4.19784 6.39531C4.16645 6.37207 4.18261 6.32231 4.22167 6.32197L4.52328 6.31967L4.61869 6.03354C4.63095 5.99653 4.68339 5.99653 4.69574 6.03354Z"
      fill="#F5F5F5"
    />
    <path
      d="M4.69574 7.53036L4.79115 7.81649L5.09277 7.81878C5.13183 7.81913 5.14799 7.86893 5.11659 7.89212L4.87397 8.07132L4.96498 8.35887C4.9768 8.39607 4.93437 8.42693 4.90258 8.40418L4.65722 8.22879L4.41186 8.40418C4.38007 8.42688 4.33774 8.39612 4.34946 8.35887L4.44047 8.07132L4.19784 7.89212C4.16645 7.86888 4.18261 7.81913 4.22167 7.81878L4.52328 7.81649L4.61869 7.53036C4.63095 7.4933 4.68339 7.4933 4.69574 7.53036Z"
      fill="#F5F5F5"
    />
    <path
      d="M4.69574 9.02714L4.79115 9.31327L5.09277 9.31557C5.13183 9.31591 5.14799 9.36571 5.11659 9.38891L4.87397 9.5681L4.96498 9.85565C4.9768 9.89286 4.93437 9.92372 4.90258 9.90096L4.65722 9.72557L4.41186 9.90096C4.38007 9.92367 4.33774 9.89286 4.34946 9.85565L4.44047 9.5681L4.19784 9.38891C4.16645 9.36566 4.18261 9.31591 4.22167 9.31557L4.52328 9.31327L4.61869 9.02714C4.63095 8.99008 4.68339 8.99008 4.69574 9.02714Z"
      fill="#F5F5F5"
    />
    <path
      d="M4.69574 10.5239L4.79115 10.81L5.09277 10.8123C5.13183 10.8127 5.14799 10.8625 5.11659 10.8857L4.87397 11.0649L4.96498 11.3524C4.9768 11.3896 4.93437 11.4205 4.90258 11.3977L4.65722 11.2223L4.41186 11.3977C4.38007 11.4204 4.33774 11.3896 4.34946 11.3524L4.44047 11.0649L4.19784 10.8857C4.16645 10.8624 4.18261 10.8127 4.22167 10.8123L4.52328 10.81L4.61869 10.5239C4.63095 10.4869 4.68339 10.4869 4.69574 10.5239Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.85751 5.29971L5.95292 5.58584L6.25453 5.58813C6.29359 5.58848 6.30976 5.63828 6.27836 5.66147L6.03573 5.84067L6.12675 6.12822C6.13857 6.16543 6.09613 6.19629 6.06435 6.17353L5.81894 5.99814L5.57358 6.17353C5.54179 6.19624 5.49946 6.16543 5.51118 6.12822L5.60219 5.84067L5.35956 5.66147C5.32817 5.63823 5.34433 5.58848 5.38339 5.58813L5.685 5.58584L5.78041 5.29971C5.79277 5.26265 5.84516 5.26265 5.85751 5.29971Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.85751 6.79648L5.95292 7.08261L6.25453 7.08491C6.29359 7.08525 6.30976 7.13505 6.27836 7.15825L6.03573 7.33745L6.12675 7.62499C6.13857 7.6622 6.09613 7.69306 6.06435 7.67031L5.81894 7.49492L5.57358 7.67031C5.54179 7.69301 5.49946 7.6622 5.51118 7.62499L5.60219 7.33745L5.35956 7.15825C5.32817 7.13501 5.34433 7.08525 5.38339 7.08491L5.685 7.08261L5.78041 6.79648C5.79277 6.75947 5.84516 6.75947 5.85751 6.79648Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.85751 8.29326L5.95292 8.5794L6.25453 8.58169C6.29359 8.58203 6.30976 8.63184 6.27836 8.65503L6.03573 8.83423L6.12675 9.12178C6.13857 9.15899 6.09613 9.18985 6.06435 9.16709L5.81894 8.9917L5.57358 9.16709C5.54179 9.1898 5.49946 9.15899 5.51118 9.12178L5.60219 8.83423L5.35956 8.65503C5.32817 8.63179 5.34433 8.58203 5.38339 8.58169L5.685 8.5794L5.78041 8.29326C5.79277 8.25625 5.84516 8.25625 5.85751 8.29326Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.85751 9.79008L5.95292 10.0762L6.25453 10.0785C6.29359 10.0788 6.30976 10.1287 6.27836 10.1518L6.03573 10.331L6.12675 10.6186C6.13857 10.6558 6.09613 10.6867 6.06435 10.6639L5.81894 10.4885L5.57358 10.6639C5.54179 10.6866 5.49946 10.6558 5.51118 10.6186L5.60219 10.331L5.35956 10.1518C5.32817 10.1286 5.34433 10.0788 5.38339 10.0785L5.685 10.0762L5.78041 9.79008C5.79277 9.75302 5.84516 9.75302 5.85751 9.79008Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.85751 11.2868L5.95292 11.573L6.25453 11.5753C6.29359 11.5756 6.30976 11.6254 6.27836 11.6486L6.03573 11.8278L6.12675 12.1154C6.13857 12.1526 6.09613 12.1834 6.06435 12.1607L5.81894 11.9853L5.57358 12.1607C5.54179 12.1834 5.49946 12.1526 5.51118 12.1154L5.60219 11.8278L5.35956 11.6486C5.32817 11.6254 5.34433 11.5756 5.38339 11.5753L5.685 11.573L5.78041 11.2868C5.79277 11.2497 5.84516 11.2497 5.85751 11.2868Z"
      fill="#F5F5F5"
    />
    <path
      d="M7.01928 6.03354L7.11469 6.31967L7.4163 6.32197C7.45536 6.32231 7.47152 6.37212 7.44013 6.39531L7.1975 6.57451L7.28852 6.86206C7.30033 6.89926 7.2579 6.93012 7.22611 6.90737L6.98075 6.73198L6.73539 6.90737C6.7036 6.93007 6.66127 6.89926 6.67299 6.86206L6.764 6.57451L6.52138 6.39531C6.48998 6.37207 6.50614 6.32231 6.54521 6.32197L6.84682 6.31967L6.94223 6.03354C6.95458 5.99653 7.00692 5.99653 7.01928 6.03354Z"
      fill="#F5F5F5"
    />
    <path
      d="M7.01928 7.53036L7.11469 7.81649L7.4163 7.81878C7.45536 7.81913 7.47152 7.86893 7.44013 7.89212L7.1975 8.07132L7.28852 8.35887C7.30033 8.39607 7.2579 8.42693 7.22611 8.40418L6.98075 8.22879L6.73539 8.40418C6.7036 8.42688 6.66127 8.39612 6.67299 8.35887L6.764 8.07132L6.52138 7.89212C6.48998 7.86888 6.50614 7.81913 6.54521 7.81878L6.84682 7.81649L6.94223 7.53036C6.95458 7.4933 7.00692 7.4933 7.01928 7.53036Z"
      fill="#F5F5F5"
    />
    <path
      d="M7.01928 9.02714L7.11469 9.31327L7.4163 9.31557C7.45536 9.31591 7.47152 9.36571 7.44013 9.38891L7.1975 9.5681L7.28852 9.85565C7.30033 9.89286 7.2579 9.92372 7.22611 9.90096L6.98075 9.72557L6.73539 9.90096C6.7036 9.92367 6.66127 9.89286 6.67299 9.85565L6.764 9.5681L6.52138 9.38891C6.48998 9.36566 6.50614 9.31591 6.54521 9.31557L6.84682 9.31327L6.94223 9.02714C6.95458 8.99008 7.00692 8.99008 7.01928 9.02714Z"
      fill="#F5F5F5"
    />
    <path
      d="M7.01928 10.5239L7.11469 10.81L7.4163 10.8123C7.45536 10.8127 7.47152 10.8625 7.44013 10.8857L7.1975 11.0649L7.28852 11.3524C7.30033 11.3896 7.2579 11.4205 7.22611 11.3977L6.98075 11.2223L6.73539 11.3977C6.7036 11.4204 6.66127 11.3896 6.67299 11.3524L6.764 11.0649L6.52138 10.8857C6.48998 10.8624 6.50614 10.8127 6.54521 10.8123L6.84682 10.81L6.94223 10.5239C6.95458 10.4869 7.00692 10.4869 7.01928 10.5239Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.18109 5.29971L8.2765 5.58584L8.57811 5.58813C8.61717 5.58848 8.63334 5.63828 8.60194 5.66147L8.35931 5.84067L8.45033 6.12822C8.46214 6.16543 8.41971 6.19629 8.38793 6.17353L8.14256 5.99814L7.8972 6.17353C7.86542 6.19624 7.82308 6.16543 7.8348 6.12822L7.92582 5.84067L7.68319 5.66147C7.65179 5.63823 7.66795 5.58848 7.70702 5.58813L8.00863 5.58584L8.10404 5.29971C8.11629 5.26265 8.16874 5.26265 8.18109 5.29971Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.18109 6.79648L8.2765 7.08261L8.57811 7.08491C8.61717 7.08525 8.63334 7.13505 8.60194 7.15825L8.35931 7.33745L8.45033 7.62499C8.46214 7.6622 8.41971 7.69306 8.38793 7.67031L8.14256 7.49492L7.8972 7.67031C7.86542 7.69301 7.82308 7.6622 7.8348 7.62499L7.92582 7.33745L7.68319 7.15825C7.65179 7.13501 7.66795 7.08525 7.70702 7.08491L8.00863 7.08261L8.10404 6.79648C8.11629 6.75947 8.16874 6.75947 8.18109 6.79648Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.18109 8.29326L8.2765 8.5794L8.57811 8.58169C8.61717 8.58203 8.63334 8.63184 8.60194 8.65503L8.35931 8.83423L8.45033 9.12178C8.46214 9.15899 8.41971 9.18985 8.38793 9.16709L8.14256 8.9917L7.8972 9.16709C7.86542 9.1898 7.82308 9.15899 7.8348 9.12178L7.92582 8.83423L7.68319 8.65503C7.65179 8.63179 7.66795 8.58203 7.70702 8.58169L8.00863 8.5794L8.10404 8.29326C8.11629 8.25625 8.16874 8.25625 8.18109 8.29326Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.18109 9.79008L8.2765 10.0762L8.57811 10.0785C8.61717 10.0788 8.63334 10.1287 8.60194 10.1518L8.35931 10.331L8.45033 10.6186C8.46214 10.6558 8.41971 10.6867 8.38793 10.6639L8.14256 10.4885L7.8972 10.6639C7.86542 10.6866 7.82308 10.6558 7.8348 10.6186L7.92582 10.331L7.68319 10.1518C7.65179 10.1286 7.66795 10.0788 7.70702 10.0785L8.00863 10.0762L8.10404 9.79008C8.11629 9.75302 8.16874 9.75302 8.18109 9.79008Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.18109 11.2868L8.2765 11.573L8.57811 11.5753C8.61717 11.5756 8.63334 11.6254 8.60194 11.6486L8.35931 11.8278L8.45033 12.1154C8.46214 12.1526 8.41971 12.1834 8.38793 12.1607L8.14256 11.9853L7.8972 12.1607C7.86542 12.1834 7.82308 12.1526 7.8348 12.1154L7.92582 11.8278L7.68319 11.6486C7.65179 11.6254 7.66795 11.5756 7.70702 11.5753L8.00863 11.573L8.10404 11.2868C8.11629 11.2497 8.16874 11.2497 8.18109 11.2868Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.34285 6.03354L9.43826 6.31967L9.73987 6.32197C9.77894 6.32231 9.7951 6.37212 9.7637 6.39531L9.52107 6.57451L9.61209 6.86206C9.62391 6.89926 9.58147 6.93012 9.54969 6.90737L9.30433 6.73198L9.05897 6.90737C9.02718 6.93007 8.98485 6.89926 8.99657 6.86206L9.08758 6.57451L8.84495 6.39531C8.81356 6.37207 8.82972 6.32231 8.86878 6.32197L9.17039 6.31967L9.2658 6.03354C9.27811 5.99653 9.33055 5.99653 9.34285 6.03354Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.34285 7.53036L9.43826 7.81649L9.73987 7.81878C9.77894 7.81913 9.7951 7.86893 9.7637 7.89212L9.52107 8.07132L9.61209 8.35887C9.62391 8.39607 9.58147 8.42693 9.54969 8.40418L9.30433 8.22879L9.05897 8.40418C9.02718 8.42688 8.98485 8.39612 8.99657 8.35887L9.08758 8.07132L8.84495 7.89212C8.81356 7.86888 8.82972 7.81913 8.86878 7.81878L9.17039 7.81649L9.2658 7.53036C9.27811 7.4933 9.33055 7.4933 9.34285 7.53036Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.34285 9.02714L9.43826 9.31327L9.73987 9.31557C9.77894 9.31591 9.7951 9.36571 9.7637 9.38891L9.52107 9.5681L9.61209 9.85565C9.62391 9.89286 9.58147 9.92372 9.54969 9.90096L9.30433 9.72557L9.05897 9.90096C9.02718 9.92367 8.98485 9.89286 8.99657 9.85565L9.08758 9.5681L8.84495 9.38891C8.81356 9.36566 8.82972 9.31591 8.86878 9.31557L9.17039 9.31327L9.2658 9.02714C9.27811 8.99008 9.33055 8.99008 9.34285 9.02714Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.34285 10.5239L9.43826 10.81L9.73987 10.8123C9.77894 10.8127 9.7951 10.8625 9.7637 10.8857L9.52107 11.0649L9.61209 11.3524C9.62391 11.3896 9.58147 11.4205 9.54969 11.3977L9.30433 11.2223L9.05897 11.3977C9.02718 11.4204 8.98485 11.3896 8.99657 11.3524L9.08758 11.0649L8.84495 10.8857C8.81356 10.8624 8.82972 10.8127 8.86878 10.8123L9.17039 10.81L9.2658 10.5239C9.27811 10.4869 9.33055 10.4869 9.34285 10.5239Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5047 5.29971L10.6001 5.58584L10.9017 5.58813C10.9408 5.58848 10.9569 5.63828 10.9255 5.66147L10.6829 5.84067L10.7739 6.12822C10.7857 6.16543 10.7433 6.19629 10.7115 6.17353L10.4661 5.99814L10.2208 6.17353C10.189 6.19624 10.1467 6.16543 10.1584 6.12822L10.2494 5.84067L10.0068 5.66147C9.97538 5.63823 9.99154 5.58848 10.0306 5.58813L10.3322 5.58584L10.4276 5.29971C10.4399 5.26265 10.4923 5.26265 10.5047 5.29971Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5047 6.79648L10.6001 7.08261L10.9017 7.08491C10.9408 7.08525 10.9569 7.13505 10.9255 7.15825L10.6829 7.33745L10.7739 7.62499C10.7857 7.6622 10.7433 7.69306 10.7115 7.67031L10.4661 7.49492L10.2208 7.67031C10.189 7.69301 10.1467 7.6622 10.1584 7.62499L10.2494 7.33745L10.0068 7.15825C9.97538 7.13501 9.99154 7.08525 10.0306 7.08491L10.3322 7.08261L10.4276 6.79648C10.4399 6.75947 10.4923 6.75947 10.5047 6.79648Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5047 8.29326L10.6001 8.5794L10.9017 8.58169C10.9408 8.58203 10.9569 8.63184 10.9255 8.65503L10.6829 8.83423L10.7739 9.12178C10.7857 9.15899 10.7433 9.18985 10.7115 9.16709L10.4661 8.9917L10.2208 9.16709C10.189 9.1898 10.1467 9.15899 10.1584 9.12178L10.2494 8.83423L10.0068 8.65503C9.97538 8.63179 9.99154 8.58203 10.0306 8.58169L10.3322 8.5794L10.4276 8.29326C10.4399 8.25625 10.4923 8.25625 10.5047 8.29326Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5047 9.79008L10.6001 10.0762L10.9017 10.0785C10.9408 10.0788 10.9569 10.1287 10.9255 10.1518L10.6829 10.331L10.7739 10.6186C10.7857 10.6558 10.7433 10.6867 10.7115 10.6639L10.4661 10.4885L10.2208 10.6639C10.189 10.6866 10.1467 10.6558 10.1584 10.6186L10.2494 10.331L10.0068 10.1518C9.97538 10.1286 9.99154 10.0788 10.0306 10.0785L10.3322 10.0762L10.4276 9.79008C10.4399 9.75302 10.4923 9.75302 10.5047 9.79008Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5047 11.2868L10.6001 11.573L10.9017 11.5753C10.9408 11.5756 10.9569 11.6254 10.9255 11.6486L10.6829 11.8278L10.7739 12.1154C10.7857 12.1526 10.7433 12.1834 10.7115 12.1607L10.4661 11.9853L10.2208 12.1607C10.189 12.1834 10.1467 12.1526 10.1584 12.1154L10.2494 11.8278L10.0068 11.6486C9.97538 11.6254 9.99154 11.5756 10.0306 11.5753L10.3322 11.573L10.4276 11.2868C10.4399 11.2497 10.4923 11.2497 10.5047 11.2868Z"
      fill="#F5F5F5"
    />
  </svg>
);

export default usFlagSvg;