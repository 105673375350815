import styled from "styled-components";

const FloatButtonWrapper = styled.div`
  display: inline-grid;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  z-index: 10;
  height: 100%;
  width: auto;
  right: 0;
  top: 0;
  max-width: 1280px;
  margin: 55px auto;
  margin-right: 1rem;
  padding: 0;

  button {
    margin: 0.2rem 0;
  }
`;

export default FloatButtonWrapper;
