import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { Form, Row, Col } from "react-bootstrap";

// pick a date util library
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl } from '@material-ui/core';

//Components
import DialogWrapper from "../UI/DialogBox/DialogWrapper";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import PersonalInformation from "./PersonalInformation";
import FormCheckout from "./Form"
import { setLocalStorage, getLocalStorage } from "../../helpers/storage";
import { ssoLogin, ssoRegister } from "../sso/SsoAuth";
import { requestSsoInfo, requestSsoToken } from "../../services/serviceAuth";

import { getStoreFromUrl } from "../../helpers/http";
import { airportStores } from "../../services/order";
import Modal from '../Modal/ReadProductModal';

moment.locale("pt-br");

const FormWrapper = styled.div`
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const CheckoutWrapper = (props) => {
  const encryptedSelectedStore = localStorage.getItem('selected-store');
  const [userInfo, setUserInfo] = useState(null);
  // const [authenticationType, setAuthenticationType] = useState(null);
  const [checkoutStep, setCheckoutStep] = useState(0);
  let store = getStoreFromUrl();
  let splashscreenUrl;
  if (store !== null){
    splashscreenUrl = airportStores[store]["splashscreen_url"];
  }  

  const confirmAuthenticationType = async (type) => {
       if (type === "login") {
            setLocalStorage("original-uri", window.location.href, 10);
            setLocalStorage("redirect-page", "checkout", 10);
            ssoLogin();
       }
       else if (type === "signup") {
            setLocalStorage("original-uri", window.location.href, 10);
            setLocalStorage("redirect-page", "checkout", 10);
            ssoRegister();
       }
       else if (type === "guest") {
            setCheckoutStep(2)
       }
  }

  const openLogin = () => {
    confirmAuthenticationType("login")
    window.gtag("event", "login", {});
  };
  const openSignup = () => {
    confirmAuthenticationType("signup")
    window.gtag("event", "sign_up", {});
  };
  const openGuest = () => {
    setShowModal(false)
    confirmAuthenticationType("guest")
    window.gtag("event", "no_account", {});
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      async function searchAuthToken(code) {
        await requestSsoToken(code)
        .then((response) => {
              let token = response.token;
              setLocalStorage('sso-auth-token', token, 10);
              let originalURI = getLocalStorage("original-uri")
              setLocalStorage("redirect-page", "checkout", 1)
              window.location.href = originalURI
        })
        .catch((error) => {
            setCheckoutStep(1)
            console.error(error);
        });
      }

      async function searchAuthInfo(token) {
        await requestSsoInfo(token)
        .then((response) => {
              setUserInfo(response)
              setCheckoutStep(2)
        })
        .catch((error) => {
            setCheckoutStep(1)
            console.error(error);
        });
      }

    let token = getLocalStorage('sso-auth-token');
    if (!token) {
        let urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get('code');
        if (code) searchAuthToken(code);
        else setCheckoutStep(1)
    }
    else {
        searchAuthInfo(token);
        setLocalStorage("redirect-page", "", 1)
    }
  }, [])

  return ReactDOM.createPortal(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {(checkoutStep !== 0) && <DialogWrapper>
        <DialogCard onClose={props.backButton} hiddenCloseButton="true">
          <DialogCardBody>
            {(checkoutStep === 1) &&
            <Row>
                <Col>
                  <FormControl>
                    <div className="checkout-logo">
                      {splashscreenUrl !== null && splashscreenUrl !== undefined && (
                        <img src={splashscreenUrl} alt="Dufry" />
                      )}
                    </div>
                    <Form.Label><b>Como deseja prosseguir?</b></Form.Label>
                    
                    <button onClick={openSignup} className="btn btn-primary btn-login mt-2">
                      <span className="text-decoration-underline">Criar conta</span> RED by Dufry
                    </button>
                    
                    <button onClick={openLogin} className="btn btn-outline-primary btn-login mt-2">
                      <span className="text-decoration-underline">Entrar na conta</span> RED by Dufry
                    </button>
                    
                    <button onClick={() => setShowModal(true)} className="btn btn-outline-secondary btn-login mt-2">
                      Continuar sem uma conta
                    </button>
                    
                    <hr className="m-2 my-4 text-muted" />

                    <button className="btn btn-outline-secondary" onClick={props.backButton}>
                      Voltar
                    </button>
                  </FormControl>
                </Col>
            </Row>
            }

            {(checkoutStep === 2) && <FormWrapper>
                <PersonalInformation
                  items={props.items}
                  userInfo={userInfo}
                  onConfirmation={(step) => {setCheckoutStep(step)}}
                  onClose={props.backButton}
                  setUserInfo={(data) => {setUserInfo(data)}}
                  />
              </FormWrapper>
            }

            {(checkoutStep === 3) && <FormWrapper>
              <FormCheckout
                items={props.items}
                userInfo={userInfo}
                onConfirmation={props.onConfirmation}
                encryptedSelectedStore={encryptedSelectedStore}
                onClose={props.backButton}
                />
              </FormWrapper>
            }
            
          </DialogCardBody>
        </DialogCard>
        <Modal
            onClose={() => setShowModal(false)}
            show={showModal}
            hideImg={true}
            title={"Tem certeza?"}
            description={"Leva menos de 2 minutos para desbloquear recompensas, descontos e benefícios exclusivos tornando-se um membro gratuitamente."}
            btnOne={"Quero criar a conta Red by Dufry"}
            onclickOne={openSignup}
            classBtnOne={"btn btn-primary btn-login w-100"}
            btnTwo={"Quero continuar sem conta"}
            classBtnTwo={"btn btn-outline-secondary btn-login w-100"}
            onclickTwo={openGuest}
          >
        </Modal>
      </DialogWrapper>}
    </MuiPickersUtilsProvider>,
    document.querySelector("#cart-root")
  );

};

export default CheckoutWrapper;
