export const OPEN_CLOSE_PRICE_DIALOG = 'OPEN_CLOSE_PRICE_DIALOG';
export const OPEN_CLOSE_QUOTA = 'OPEN_CLOSE_QUOTA';
export const OPEN_CLOSE_CART = 'OPEN_CLOSE_CART';
export const OPEN_CLOSE_CHECKOUT = 'OPEN_CLOSE_CHECKOUT';
export const OPEN_CLOSE_CHECKOUT_CONFIRMATION = 'OPEN_CLOSE_CHECKOUT_CONFIRMATION';
export const OPEN_CLOSE_BARCODE_INPUT = 'OPEN_CLOSE_BARCODE_INPUT';
export const OPEN_CLOSE_ADD_TO_CART = 'OPEN_CLOSE_ADD_TO_CART';
export const OPEN_CLOSE_ARE_YOU_SURE = 'OPEN_CLOSE_ARE_YOU_SURE';

export const openClosePriceDialog = (boolValue) => ({
    type: OPEN_CLOSE_PRICE_DIALOG,
    payload: boolValue
});

export const openCloseQuota = (boolValue) => ({
    type: OPEN_CLOSE_QUOTA,
    payload: boolValue
});

export const openCloseCart = (boolValue) => ({
    type: OPEN_CLOSE_CART,
    payload: boolValue
});

export const openCloseCheckout = (boolValue) => ({
    type: OPEN_CLOSE_CHECKOUT,
    payload: boolValue
});

export const openCloseCheckoutConfirmation = (boolValue) => ({
    type: OPEN_CLOSE_CHECKOUT_CONFIRMATION,
    payload: boolValue
});

export const openCloseBarcodeInput = (boolValue) => ({
    type: OPEN_CLOSE_BARCODE_INPUT,
    payload: boolValue
});

export const openCloseAddToCart = (boolValue) => ({
    type: OPEN_CLOSE_ADD_TO_CART,
    payload: boolValue
});

export const openCloseAreYouSure = (boolValue) => ({
    type: OPEN_CLOSE_ARE_YOU_SURE,
    payload: boolValue
});