import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

// Components
import DialogWrapper from "../UI/DialogBox/DialogWrapper";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import CallButton from "../UI/Buttons/CallButton";

// Icons
import { IconContext } from "react-icons";
import { BsSearch as CartIcon } from "react-icons/bs";

const Wrapper = styled.div`
  .card-body .main .body {
    padding-left: 0.5rem;
    margin-top: 1rem;
  }

  .card .card-body {
    background-color: transparent;

    .main {
      margin-bottom: 20px;

      .header {
        .button-close {
          border: none;
          font-size: 12px;
        }
      }
    }
  }

  .form-salesman-input {
    input {
      display: flex;
      height: 2.5rem;
      color: #252525;
      width: 100%;
      
      &:focus {
        border: 1px solid #fff;
     }
  }
  .invalid {
    background-color: #ff4500;
    background-clip: padding-box;
    border: 2px solid #ff4500;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  }
  span {
    color: white;
  }
`;

const SalesmanInput = (props) => {
    const [salesmanCode, setSalesmanCode] = useState("");
    const [requestError, setRequestError] = useState("");
    const isSalesmanCodeValid = (value) => value.startsWith("0") && value.length === 7;
    const formSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const CartButtonStyled = styled(CallButton)`
      &.bump {
      }
    `;

    const searchSalesmanByCode =  () => {
        if (salesmanCode.length === 0) return;
        window.gtag("event", "type_staff", {});
        setSalesmanCode(salesmanCode);
        setRequestError("");
        if (!isSalesmanCodeValid(salesmanCode)) {
            const errorMsg = "Código do vendedor inválido!"
            setRequestError(errorMsg);
            return;
        };

        props.onGetSalesmanCode(salesmanCode);
        props.onCloseSalesmanInput();
    };

    return (
        <IconContext.Provider value={{ size: "1.7em" }}>
        <Wrapper>
            <DialogWrapper>
            <DialogCard onClose={props.onCloseSalesmanInput} className="small">
                <DialogCardBody>
                    <div className="row align-items-end">
                        <div className="col">
                            <Form onSubmit={formSubmit} className="form-salesman-input">
                                <label className="text-white mb-2">Código do vendedor</label>
                                <input 
                                autoFocus
                                id="salesman"
                                type="text" 
                                name="salesman" 
                                value={salesmanCode}
                                onChange={(e) => setSalesmanCode(e.target.value)}
                                placeholder="Informe o código do vendedor"
                                ></input>
                            </Form>
                        </div>
                        <div className="col-auto">
                            <IconContext.Provider value={{ size: "1.3em" }}>
                                <CartButtonStyled   CartButtonStyled
                                size="2.5rem"
                                variant="outline-light"
                                onClick={searchSalesmanByCode}
                                className={"bump"}
                                >
                                <CartIcon />
                                </CartButtonStyled>
                            </IconContext.Provider>
                        </div>
                    </div>
                    
                   
                    <div>
                        {requestError && requestError.length > 0 && <span>{requestError}</span>}
                    </div>
                </DialogCardBody>
            </DialogCard>
            </DialogWrapper>
        </Wrapper>
        </IconContext.Provider>
    );
};

export default SalesmanInput;