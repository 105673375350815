import React from "react";
import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";

// Icons
import { IconContext } from "react-icons";
// import { FaTimes } from "react-icons/fa";
import { ReactComponent as TrashIcon } from "../../assets/images/icons/trash.svg";

const BoxWrapper = styled.div`
  width: 96%;
  min-height: 4rem;
  background-color: ${(props) => props.backgroundColor || "#CD8500"};
  color: ${(props) => props.textColor || "#FFF"};
  border-color: ${(props) => props.borderColor || "unset"};
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-bottom: 5px;
  align-items: start;  
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  .button-close {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: unset;
    padding: 0;
    border: none;

    ${(props) =>
      props.buttonCloseRounded &&
      css`
        border-radius: 10px;
        line-height: 1;
      `}

    svg {
      vertical-align: text-bottom;
    }
  }

  header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    z-index: 10;
  }

  section {
    margin-top: -1.9rem;
    width: 100%;
    z-index: 0;
  }
`;

const DivFix = styled.div`
  width: 1.4rem;
  height: 1.4rem;
`;

const Box = (props) => {
  let buttonTheme = "outline-light";

  if (
    props.backgroundColor === "#FFF" ||
    (props.theme && props.theme === "dark")
  ) {
    buttonTheme = "outline-dark";
  }

  return (
    <IconContext.Provider value={{ size: "1.2em" }}>
      <BoxWrapper {...props}>
        <header>
          {props.onClose ? (
            <Button
              variant={buttonTheme}
              size="sm"
              onClick={props.onClose}
              className="button-close"
            >
              <TrashIcon width={18} height={18} />
            </Button>
          ) : (
            <DivFix />
          )}
        </header>
        <section>{props.children}</section>
      </BoxWrapper>
    </IconContext.Provider>
  );
};

export default Box;
