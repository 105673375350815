import { get_env } from '../../helpers/env';

const urlBase = get_env('REACT_APP_SSO_URL_BASE');
const redirect_uri = get_env('REACT_APP_SSO_REDIRECT_URI');
const client_id = get_env('REACT_APP_SSO_CLIENT_ID');
const scope='openid+email'

export const ssoLogin = () => {
    try {
        let uri = redirect_uri;
        const sso_url=`${urlBase}/login?response_type=code&redirect_uri=${uri}&client_id=${client_id}&scope=${scope}&lang=pt&source=webar&privacy_url=null`;
        window.location.href = sso_url;
    } catch (error) {
        console.error(error);
    }
};

export const ssoRegister = () => {
    try {
        let uri = redirect_uri;
        const sso_url=`${urlBase}/register?response_type=code&redirect_uri=${uri}&client_id=${client_id}&scope=${scope}&privacy_url=null`;
        window.location.href = sso_url;
    } catch (error) {
        console.error(error);
    }
};