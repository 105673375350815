import styled from "styled-components";
import { Form } from "react-bootstrap";

const StyledForm = styled(Form)`
  width: 96%;

  .alert.alert-danger {
    border-radius: 0;
  }

  .row {
    margin-right: 0;
    margin-left: 0;

    > * {
      padding-left: 0;
    }
  }

  .steps {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items:center;
    margin: 5px auto;
    
    div {
      width: 100%;
      height: 2px;
      background: #ced4da;
      position: relative;
      
      &::after {
        content: "";
        position: absolute;
        left: 0; top: 0;
        width: 100%;height: 100%;
        background: #E00F15;
        transform-origin: left;
        transform: scaleX(0);
        
        transition: transform .5s ease-in-out;
      }
      
      &.active {
        &::after {
          transform: scaleX(1)
        }
      }
    }
  
    span {
      cursor: pointer;
      background: transparent;
      border: 2px solid #ced4da;
      background: #ced4da;
      border-radius: 100%;
      min-width: 10px;
      height: 10px;
      padding: 0;
      box-shadow: 0.9px 2.8px 2.2px rgba(49, 140, 252, 0.02),
        2.1px 6.7px 5.3px rgba(49, 140, 252, 0.028),
        4px 12.5px 10px rgba(49, 140, 252, 0.035),
        7.1px 22.3px 17.9px rgba(49, 140, 252, 0.042),
        13.4px 41.8px 33.4px rgba(49, 140, 252, 0.05),
        32px 100px 80px rgba(49, 140, 252, 0.07);
  
      transition: all .3s ease;
  
      &.active {
        border: 2px solid #E00F15;
        background: #E00F15;
        color: white;
      }
    }
  }

  .btn-reserve {
    padding: 10px 15px!important;
  }

  .datepicker-styled {
    label {
      font-size: 14px;
      margin-bottom: 0.5rem;
      color: #000;
      transform: translate(0, 1.5px) scale(1);
    }

    .small {
      font-size: 18px!important;
    }

    .MuiInputBase-root {
      height: 38px;
      margin-top: 1.5rem;
      border: 1px solid #ced4da;
      background-color: #fff;

      &.MuiInput-underline:after, &.MuiInput-underline:before {
        border-bottom: none;
      }

      .MuiInput-input {
        color: #000;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 0;
        background-color: #fff;

        .MuiIconButton-root {
          color: #E00F15;
        }
      }
    }
  }

  .form-control,
  .form-select {
    border-radius: unset;
    background-color: var(--bs-white);
    color: var(--bs-dark);

    &:focus {
      border-color: var(--bs-dark);
      box-shadow: unset;
    }    
  }

  .form-label {
    font-size:14px;
  }

  .form-floating {
    span {
      font-size: 10px;
      color: #f5f5f5;
    }
  }

  .form-floating > label {
    top: -5px;
  }

  .PhoneInput {
    &.form-control input {
      background: transparent;
      color: #252525;
      border: none;
    }
  }

  .PhoneInput--focus {
    border: none !important;
  }

  .form-control.is-invalid, .form-control:invalid {
    border-color: #dc3545 !important;
  }

  .invalid {
    background-clip: padding-box;
    border: 2px solid #ff4500 !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .form-control:focus {
      outline: 0;
      box-shadow: unset;
    }
  }
`;

export default StyledForm;
