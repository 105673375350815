import axios from 'axios';
import { get_env } from '../helpers/env';
import { airportStores } from "../services/order";

const API = axios.create({
    baseURL: get_env('REACT_APP_BASE_URL_ORDER_API', '/local-api'),
    headers: {
        "content-type": "application/json"
    }
})

export const getCurrency = (store) => {
    let currency_rate 
    const findStore = airportStores[store]
    if (findStore === undefined) {
        currency_rate = airportStores["default"]["currency_rate"];
    } else{
        currency_rate = airportStores[store]["currency_rate"];
    }

    if (store && currency_rate === undefined)
        return API.get(`/exchange-rates`);
    return new Promise((resolve) => {
        resolve({
            data: [
                {
                    "currency_to": "BRL",
                    "rate":currency_rate,
                    "updated_at": "2022-01-08"
                },
                {
                    "currency_to": "USD",
                    "rate": 1.0,
                    "updated_at": "2021-05-13"
                }
            ]
        })
    });

   
}

export const getProductByEanFromProductAPI = (barcode) => {
    return API.get(`/product?ean=${barcode}`);
}

export const getProductByGammaCodeFromProductAPI = (barcode) => {
    return API.get(`/product/${barcode}`);
}

export const getProductBySapCodeFromProductAPI = (barcode) => {
    return API.get(`/product?sap_code=${barcode}`);
}

export const getProduct = async (barcodeValue) => {
    let error_response = {
      "status": 404,
      "error": true
    }

    let response = await getProductByEanFromProductAPI(barcodeValue)
    .then((res) => {
      return res;
    }).catch((error) => {
      return error_response;
    })

    if (response.status !== 200 || response.data.error) {
      response = await getProductByGammaCodeFromProductAPI(barcodeValue)
      .then((res) => {
        return res;
      }).catch((error) => {
        return error_response;
      })
      
      if (response.status !== 200 || response.data.error) {
        response = await getProductBySapCodeFromProductAPI(barcodeValue)
        .then((res) => {
          return res;
        }).catch((error) => {
          return error_response;
        })
      }
    }
    
    return response
  };

