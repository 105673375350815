import React, { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { Form, Row, Alert, Col } from "react-bootstrap";
import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import { getStoreFromUrl } from "../../helpers/http";
import InputMask from 'react-input-mask';

//Css
import "react-phone-number-input/style.css";

//Hooks
import useInput from "../../hooks/use-input";

//UI
import StyledForm from "../UI/Forms/StyledForm";
import DialogTagWrapper from "../UI/DialogBox/DialogTagWrapper";

//Components
import CartItemSummary from "../Cart/CartItem/CartItemSummary";

//Helpers
import { decryptText, validateEmail } from "../../helpers/string";

//Services
import {
  airportStores,
  createOrder,
  addItemsInOrder,
} from "../../services/order";
import FeedbackFailed from "../UI/Forms/FeedbackFailed";
import {get_env_percent} from "../../helpers/env";

//Locale picker
moment.locale("pt-br");

const DateTimePickupText = styled.p`
  text-align: justify;
  font-size: 0.85rem;
`;

//Datepicker theme
const defaultPickerTheme = createTheme({
  palette: {
    primary: red,
  },
});

//Datepicker
const DateTimePickerStyled = (props) => {
  const {
    pickupDate,
    dateFormat,
    nextDay,
    rangeLimit,
    pickerClasses,
    onChange,
    onBlur,
  } = props;

  return (
    <ThemeProvider theme={defaultPickerTheme}>
      <KeyboardDateTimePicker
        title="Finalizar Reserva"
        variant="dialog"
        ampm={false}
        label="Data/hora da retirada"
        value={pickupDate}
        onChange={onChange}
        onBlur={onBlur}
        disablePast
        format={dateFormat}
        initialFocusedDate={nextDay}
        minDate={nextDay}
        maxDate={rangeLimit}
        onError={() => {}}
        invalidDateMessage=""
        maxDateMessage="Data/hora da retirada não deve ser superior a 35 dias após a data de amanhã (24hs após data atual)."
        className={pickerClasses}
      />
    </ThemeProvider>
  );
};



const FormCheckout = (props) => {
  const selectedStore = decryptText(props.encryptedSelectedStore);
  
  const userInfo = props.userInfo;
  const userFirstName = userInfo !== null ? userInfo.firstname : '';
  const userLastName = userInfo !== null ? userInfo.lastname : '';
  const userEmail = userInfo !== null ? userInfo.email : '';
  let userPhone = userInfo !== null ? userInfo.phone_number : '';

  if (userPhone == null) userPhone = '';
  else userPhone = userPhone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');

  const userAddress = userInfo !== null ? userInfo.address : '';
  const addressNumber = userInfo !== null ? userInfo.address_number : '';
  const addressComplement = userInfo !== null ? userInfo.address_complement : '';
  const addressCity = userInfo !== null ? userInfo.address_city : '';
  const addressUF = userInfo !== null ? userInfo.address_uf : '';
  const userCPF = userInfo !== null ? userInfo.user_cpf : '';
  

  const [isPickUpInStoreButton, setIsPickUpInStoreButton ] = useState(false);
  const [isReceiveAtHomeButton, setIsReceiveAtHomeButton ] = useState(false);

  const storeFromUrl = getStoreFromUrl();
  const equalStores = selectedStore === storeFromUrl
  const dateFormat = "DD/MM/YYYY HH:mm";
  
  const [loadRequest, setLoadRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //Pickup date
  let initalPickupDate = null
  if (equalStores){
    initalPickupDate = moment().add(1, 'hours');
  }
  
  const [pickupDate, setPickupDate] = useState(initalPickupDate);
  const [isInvalidPickupDate, setIsInvalidPickupDate] = useState(false);

  const pickupDateValidation = (value) => {
    if (equalStores)
      return value !== null && value.isValid() && value.isSameOrAfter(endOfDay);
    return value !== null && value.isValid() && value.isAfter(endOfDay);
  }

  const pickupDateChangeHandler = (value) => {
    setPickupDate(value);
    window.gtag("event", "begin_checkout", {});
    if (pickupDateValidation(value)) {
      setIsInvalidPickupDate(false);
    }
  };

  const pickupDateBlurHandler = (e) => {
    const value = e.target.value;

    if (value.indexOf("_") > -1) {
      setIsInvalidPickupDate(true);
      return;
    }

    const dateTime = moment(value, dateFormat);

    if (!pickupDateValidation(dateTime)) {
      setIsInvalidPickupDate(true);
      return;
    }

    setIsInvalidPickupDate(false);
  };

  const onResetPickupDateInput = () => {
    setPickupDate(null);
    setIsInvalidPickupDate(false);
  };

  //Phone input
  const [phone, setPhone] = useState(userPhone);
  const [isTouchedPhone, setIsTouchedPhone] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  // Pickup Store
  const pickupStoreKey = "pickup-store";
  const pickupStoreLocalStorage = localStorage.getItem(pickupStoreKey);
  const decryptedPickupStore =
    pickupStoreLocalStorage === null
      ? ""
      : decryptText(pickupStoreLocalStorage);
  const [pickupAirportStore, setPickupAirportStore] =
    useState(decryptedPickupStore);

  const airportData = airportStores[pickupAirportStore];
  const minBookingPeriod = airportData ? airportData["min_booking_period"] : undefined;
  const messageDatePicker = minBookingPeriod ?
      "Por favor preencha com a data/hora de retirada do seu pedido com pelo menos "
      + ( minBookingPeriod > 60 ? (minBookingPeriod/60 + (minBookingPeriod/60 > 1 ? " horas" : "hora" ) ) : minBookingPeriod + " minutos" )
      + " antes do seu embarque ou desembarque."
      : "Por favor preencha com a data/hora de retirada do seu pedido com pelo menos 24 horas antes do seu embarque ou desembarque.";
  let endOfDay = minBookingPeriod ? moment() : moment().hours(23).minutes(59).seconds(59);
  let nextDay = minBookingPeriod ? moment().add(minBookingPeriod, "minute") : moment().add("1", "day");
  let rangeLimit = moment().add("36", "days");

  if (equalStores){
    nextDay = moment();
    rangeLimit = moment();
    endOfDay = moment().minutes(0).seconds(0);
  }

  const onValidatePickupStore = () => {
    if (
      decryptedPickupStore === null ||
      (decryptedPickupStore !== null &&
        !Object.keys(airportStores).includes(decryptedPickupStore))
    ) {
      return false;
    }

    if (decryptedPickupStore !== pickupAirportStore)
      setPickupAirportStore(decryptedPickupStore);
    setErrorMessage("");
    return true;
  };

  const onResetPickupAirportInput = () => {
    setPickupAirportStore("");
    localStorage.removeItem(pickupStoreKey);
  };

  const {
    value: email,
    isInvalid: isInvalidEmail,
    onChangeHandler: onChangeEmailHandler,
    onBlurHandler: onBlurEmailHandler,
    onReset: onResetEmailInput,
    onValidate: onValidateEmailInput,
  } = useInput(validateEmail, userEmail);

  const firstNameValidation = (value) => value.length >= 3;
  const {
    value: firstName,
    isInvalid: isInvalidFirstName,
    onChangeHandler: onChangeFirstNameHandler,
    onBlurHandler: onBlurFirstNameHandler,
    onReset: onResetFirstNameInput,
    onValidate: onValidateFirstNameInput,
  } = useInput(firstNameValidation, userFirstName);

  const lastNameValidation = (value) => value.length >= 3;
  const {
    value: lastName,
    isInvalid: isInvalidLastName,
    onChangeHandler: onChangeLastNameHandler,
    onBlurHandler: onBlurLastNameHandler,
    onReset: onResetLastNameInput,
    onValidate: onValidateLastNameInput,
  } = useInput(lastNameValidation, userLastName);

  const country = "BR";
  const countryCode = "+55";

  const phoneValidation = (value) => {
    let phoneValue = value === undefined ? "" : value;
    if (!phoneValue.startsWith("+")) {
        phoneValue = countryCode + phoneValue;
    }
    phoneValue = "+" + phoneValue.replace(/[^\d]/g, "");
    return isValidPhoneNumber(phoneValue);
  };

  const phoneChangeHandler = (value) => {
    if (!isTouchedPhone) setIsTouchedPhone(true);
    setPhone(value);
  };

  const phoneBlurHandler = (e) => {
    const value = e.target.value;

    if (!isTouchedPhone) setIsTouchedPhone(true);

    if (!phoneValidation(value)) {
      setIsInvalidPhone(true);
      return;
    }

    setIsInvalidPhone(false);
  };

  const onResetPhoneInput = () => {
    setPhone("");
    setIsTouchedPhone(false);
    setIsInvalidPhone(false);
  };

  const travelerDocTypeValidation = (value) => value.length > 0;
  const {
    value: travelerDocType,
    isInvalid: isInvalidTravelerDocType,
    onChangeHandler: onChangeTravelerDocTypeHandler,
    onReset: onResetTravelerDocTypeInput,
    onValidate: onValidateTravelerDocTypeInput,
  } = useInput(travelerDocTypeValidation, 0);

  const travelerDocValidation = (value) => value.length > 0;
  const {
    value: travelerDoc,
    isInvalid: isInvalidTravelerDoc,
    onChangeHandler: onChangeTravelerDocHandler,
    onBlurHandler: onBlurTravelerDocHandler,
    onReset: onResetTravelerDocInput,
    onValidate: onValidateTravelerDocInput,
  } = useInput(travelerDocValidation);
  
  const addressValidation = (value) => value.length >= 3;
  const {
    value: address,
    isInvalid: isInvalidAddress,
    onChangeHandler: onChangeAddressHandler,
    onBlurHandler: onBlurAddressHandler,
    onReset: onResetAddressInput,
    onValidate: onValidateAddressInput,
  } = useInput(addressValidation , userAddress);

 
  const addressNumberValidation = (value) => value.length >= 3;
  const {
    value: address_number,
    isInvalid: isInvalidAddressNumber,
    onChangeHandler: onChangeAddressNumberHandler,
    onBlurHandler: onBlurAddressNumberHandler,
    onReset: onResetAddressNumberInput,
    onValidate: onValidateAddressNumberInput,
  } = useInput(addressNumberValidation, addressNumber);

  const addressComplementValidation = (value) => value.length >= 0;
  const {
    value: address_complement,
    onChangeHandler: onChangeAddressComplementHandler,
    onBlurHandler: onBlurAddressComplementHandler,
    onReset: onResetAddressComplementInput,
    onValidate: onValidateAddressComplementInput,
  } = useInput(addressComplementValidation, addressComplement);

  const addressCityValidation = (value) => value.length >= 3;
  const {
    value: address_city,
    isInvalid: isInvalidAddressCity,
    onChangeHandler: onChangeAddressCityHandler,
    onBlurHandler: onBlurAddressCityHandler,
    onReset: onResetAddressCityInput,
    onValidate: onValidateAddressCityInput,
  } = useInput(addressCityValidation, addressCity);

  const addressUFValidation = (value) => value.length > 0;
  const {
    value: address_uf,
    isInvalid: isInvalidAddressUF,
    onChangeHandler: onChangeAddressUFHandler,
    onReset: onResetAddressUFInput,
    onValidate: onValidateAddressUFInput,
  } = useInput(addressUFValidation, addressUF);

  const userCPFValidation = (value) => value.length >= 3;
  const {
    value: user_cpf,
    isInvalid: isInvalidUserCpf,
    onChangeHandler: onChangeUserCpfHandler,
    onBlurHandler: onBlurUserCpfHandler,
    onReset: onResetUserCpfInput,
    onValidate: onValidateUserCpfInput,
  } = useInput(userCPFValidation, userCPF);

  const submitForm = (event) => {
    event.preventDefault();
    return false;
  };

  const isValidForm = () => {
    let qtyInvalid = 0;

    if (!pickupDateValidation(pickupDate)) {
      setIsInvalidPickupDate(true);
      qtyInvalid += 1;
    }

    if (!onValidatePickupStore()) {
      setErrorMessage(
        "Volte à tela do carrinho e selecione a loja de retirada para prosseguir."
      );
      qtyInvalid += 1;
    }

    // if (!phoneValidation(phone)) {
    //   setIsInvalidPhone(true);
    //   qtyInvalid += 1;
    // }

    // if (!onValidateEmailInput(email)) {
    //   qtyInvalid += 1;
    // }

    // if (!onValidateFirstNameInput(firstName)) {
    //   qtyInvalid += 1;
    // }

    // if (!onValidateLastNameInput(lastName)) {
    //   qtyInvalid += 1;
    // }

    if (!onValidateTravelerDocTypeInput(travelerDocType)) {
      qtyInvalid += 1;
    }

    if (!onValidateTravelerDocInput(travelerDoc)) {
      qtyInvalid += 1;
    }

    if (!onValidateAddressInput(address) && isReceiveAtHomeButton) {
      qtyInvalid += 1;
    }

    if (!onValidateAddressNumberInput(address_number) && isReceiveAtHomeButton) {
      qtyInvalid += 1;
    }

    if (!onValidateAddressCityInput(address_city) && isReceiveAtHomeButton) {
      qtyInvalid += 1;
    }

    if (!onValidateAddressUFInput(address_uf) && isReceiveAtHomeButton) {
      qtyInvalid += 1;
    }

    if (!onValidateUserCpfInput(user_cpf) && isReceiveAtHomeButton) {
      qtyInvalid += 1;
    }

    if (qtyInvalid > 0) return false;

    return true;
  };

  const SHOW_RC_DISCOUNT = true; // 15% discount on R&C
  const PERCENT_DISCOUNT = get_env_percent('REACT_APP_DISCOUNT_VALUE', '')

  const confirmOrder = async () => {
    try {
      if (!isValidForm()) return false;
      let formattedPhone = props.userInfo.phone;
      if (!formattedPhone.startsWith("+")) {
          formattedPhone = countryCode + formattedPhone;
      }
      formattedPhone = "+" + formattedPhone.replace(/[^\d]/g, "");
      const orderData = {
        firstname: props.userInfo.firstname,
        lastname: props.userInfo.lastname,
        email: props.userInfo.email,
        phone: formattedPhone,
        airport_store: pickupAirportStore,
        pickup_date: pickupDate,
        travel_document_type: travelerDocType,
        travel_document: travelerDoc,
        address: address,
        address_number: address_number,
        address_complement: address_complement,
        address_city: address_city,
        address_uf: address_uf,
        user_cpf: user_cpf,
        home_delivery: isReceiveAtHomeButton,
      };

      setLoadRequest(true);
      const dataCreateOrder = await createOrder(orderData);

      if (Object.keys(dataCreateOrder).includes("detail")) {
        setLoadRequest(false);
        setErrorMessage(dataCreateOrder["detail"]);
        return false;
      }

      const orderId = dataCreateOrder["id"];

      const dataAddItems = await addItemsInOrder(orderId, props.items);

      if (Object.keys(dataAddItems).includes("detail")) {
        setErrorMessage(dataAddItems["detail"]);
        return false;
      }

      setErrorMessage("");
      setTimeout(() => {
        onResetPickupDateInput();
        onResetPickupAirportInput();
        onResetFirstNameInput();
        onResetLastNameInput();
        onResetEmailInput();
        onResetPhoneInput();
        onResetTravelerDocTypeInput();
        onResetTravelerDocInput();
        onResetAddressInput();
        onResetAddressNumberInput();
        onResetAddressComplementInput();
        onResetAddressCityInput();
        onResetAddressUFInput();
        onResetUserCpfInput();
        setLoadRequest(false);
        props.onConfirmation(orderId);
      }, 1500);

      var itemsGtag = [];
      let subtotal = 0;
      let discount = 0;
      for (const item of props.items) {
        let price = item.price;
        const specialPrice = item.specialPrice;
        let discount20 = 0;
        let priceWith20Discount = 0;

        if (SHOW_RC_DISCOUNT && item.categoryCode !== "010") { // Avoid tobacco discount
          discount20 = price * PERCENT_DISCOUNT;
          priceWith20Discount = price - discount20;
          priceWith20Discount = priceWith20Discount < 0 ? 0 : priceWith20Discount;
        }

        if (specialPrice > 0 && specialPrice < priceWith20Discount) {
          price = specialPrice;
          discount20 = 0;
        }

        itemsGtag.push({
          item_id: item.sku,
          item_name: item.name
        });
        subtotal += price * item.amount;
        discount += discount20 * item.amount;
      }
      window.gtag("event", "purchase", {
        currency: props.items[0].currency,
        transaction_id: orderId,
        value: subtotal - discount,
        items: itemsGtag
      });

    } catch (e) {
      setLoadRequest(false);
      setErrorMessage(e.message);
    }
  };

  function onclickPickUpButton() {
    setIsPickUpInStoreButton(true);
    setIsReceiveAtHomeButton(false);
  }
  
  function onclickReceiveAtHomeButton() {
    setIsReceiveAtHomeButton(true);
    setIsPickUpInStoreButton(true);
  }

  let pickerClasses = isInvalidPickupDate
    ? "datepicker-styled is-invalid"
    : "datepicker-styled";

  return (
    
    <React.Fragment>
      <StyledForm onSubmit={submitForm}>
        <h6 className="title-form"><b>Finalizar Reserva</b></h6>
        <div className="steps" id="steps">
          <span data-step="1" className="active"></span>
          <div className="active"></div>
          <span data-step="2" className="active"></span>
          <div className="active"></div>
          <span data-step="3" className="active"></span>
        </div>
        <Row className="mb-2">
          <Col className="col-6">
            <button
              className="btn btn-outline-secondary mt-2 btn-reserve w-100"
              onClick={onclickPickUpButton}
            >
              Retirar na loja
            </button>
          </Col>
          <Col className="col-6">
            <button 
              className="btn btn-outline-secondary mt-2 btn-reserve w-100"
              onClick={onclickReceiveAtHomeButton}
            >
              Receber em casa
            </button>
          </Col>
        </Row>
        {isPickUpInStoreButton && <div>
        <Row className="mb-2">
          <DateTimePickerStyled
            pickupDate={pickupDate}
            onChange={pickupDateChangeHandler}
            onBlur={pickupDateBlurHandler}
            dateFormat={dateFormat}
            nextDay={nextDay}
            rangeLimit={rangeLimit}
            pickerClasses={pickerClasses}
            name="date"
          />

          {isInvalidPickupDate && (
            <FeedbackFailed className="mt-1">
              Por favor preencha com uma data e hora válida.
            </FeedbackFailed>
          )}
        </Row>
        <Row className="mb-3">
          {!equalStores && (<DateTimePickupText>
            <div className="label-info">{messageDatePicker}</div>
            {/* <br />
            Todas as datas e horas são calculadas utilizando o fuso-horário
            local no Brasil (GMT -3 horas) */}
          </DateTimePickupText>
          )}
        </Row>
        <Row className="mb-3">
          <Form.Label>Tipo de documento de viagem <span className="text-primary">*</span></Form.Label>
          <Form.Group controlId="floatingInputTravelerDocumentType">
            <Form.Select
              onChange={onChangeTravelerDocTypeHandler}
              isInvalid={isInvalidTravelerDocType}
            >
              <option value="">--Por favor selecione--</option>
              <option value="Passaporte">Passaporte</option>
              <option value="Carteira de Identidade (Apenas Mercosul)">
                Carteira de Identidade (Apenas Mercosul)
              </option>
              <option value="Certificado de Tripulação">
                Certificado de Tripulação
              </option>
              <option value="Laissez Passez">laissez Passez</option>
              <option value="Título de Nacionalidade">
                Título de Nacionalidade
              </option>
              <option value="Permissão de Reingresso">
                Permissão de Reingresso
              </option>
              <option value="Salvo Conduto">Salvo Conduto</option>
              <option value="Carteira Marítima">Carteira Marítima</option>
            </Form.Select>
            {isInvalidTravelerDocType && (
              <FeedbackFailed>
                Por favor selecione o tipo do documento de viagem.
              </FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">    
          <Form.Group controlId="floatingInputTravelerDocument">
            <Form.Label>Número do documento de viagem <span className="text-primary">*</span></Form.Label>
            <Form.Control
              type="text"
              value={travelerDoc}
              onChange={onChangeTravelerDocHandler}
              onBlur={onBlurTravelerDocHandler}
              isInvalid={isInvalidTravelerDoc}
              placeholder=""
            />
            {isInvalidTravelerDoc && (
              <FeedbackFailed>
                Por favor preencha com o documento de viagem.
              </FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        {isReceiveAtHomeButton  && <div>
          <Row className="mb-3">
            <Form.Group controlId="floatingInputAddress">
              <Form.Label>Endereço de entrega <span className="text-primary">*</span></Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={onChangeAddressHandler}
                onBlur={onBlurAddressHandler}
                isInvalid={isReceiveAtHomeButton ? isInvalidAddress : false}
                placeholder="Endereço de entrega"
              />
              {(isInvalidAddress && isReceiveAtHomeButton) && (
                <FeedbackFailed>
                  Por favor preencha com um endereço.
                </FeedbackFailed>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="floatingInputAddressNumber">
              <Form.Label>Número <span className="text-primary">*</span></Form.Label>
              <Form.Control
                type="text"
                value={address_number}
                onChange={onChangeAddressNumberHandler}
                onBlur={onBlurAddressNumberHandler}
                isInvalid={isReceiveAtHomeButton ? isInvalidAddressNumber : false}
                placeholder="Número"
              />
              {(isInvalidAddressNumber && isReceiveAtHomeButton) && (
                <FeedbackFailed>
                  Por favor preencha com um número.
                </FeedbackFailed>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="floatingInputAddressComplement">
              <Form.Label>Complemento do endereço</Form.Label>
              <Form.Control
                type="text"
                value={address_complement}
                onChange={onChangeAddressComplementHandler}
                onBlur={onBlurAddressComplementHandler}
                placeholder="Complemento do endereço"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="floatingInputAddressCity">
              <Form.Label>Cidade <span className="text-primary">*</span></Form.Label>
              <Form.Control
                type="text"
                value={address_city}
                onChange={onChangeAddressCityHandler}
                onBlur={onBlurAddressCityHandler}
                isInvalid={isReceiveAtHomeButton ? isInvalidAddressCity : false}
                placeholder="Cidade"
              />
              {(isInvalidAddressCity && isReceiveAtHomeButton)  && (
                <FeedbackFailed>
                  Por favor preencha com uma cidade.
                </FeedbackFailed>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>UF <span className="text-primary">*</span></Form.Label>
            <Form.Group controlId="floatingInputAddressUF" className="">
              <Form.Select
                onChange={onChangeAddressUFHandler}
                isInvalid={isReceiveAtHomeButton ? isInvalidAddressUF : false}
              >
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </Form.Select>
              {(isInvalidAddressUF && isReceiveAtHomeButton) && (
                <FeedbackFailed>
                  Por favor selecione um estado.
                </FeedbackFailed>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="floatingInputCPF">
              <Form.Label>CPF <span className="text-primary">*</span></Form.Label>
              <Form.Control
                type="text"
                as={InputMask}
                mask="999.999.999-99"
                value={user_cpf}
                onChange={onChangeUserCpfHandler}
                onBlur={onBlurUserCpfHandler}
                isInvalid={isReceiveAtHomeButton ? isInvalidUserCpf : false}
                placeholder="CPF"
              />
              {(isInvalidUserCpf && isReceiveAtHomeButton) && (
                <FeedbackFailed>
                  Por favor preencha com um CPF.
                </FeedbackFailed>
              )}
            </Form.Group>
          </Row>
          </div>
        }
        </div>
      }
      </StyledForm>
      {props.items.length > 0 && (
        <CartItemSummary items={props.items}>
          {errorMessage && (
            <div className="error-message">
              <Alert variant="danger">{errorMessage}</Alert>
            </div>
          )}

          <DialogTagWrapper>
            <button
              className="btn btn-outline-secondary mt-2"
              onClick={props.onClose}
            >
              Voltar
            </button>
            {(isReceiveAtHomeButton  || isPickUpInStoreButton) &&
              <button
                className="btn btn-secondary mt-2"
                onClick={confirmOrder}
                disabled={loadRequest}
              >
                {loadRequest ? "Processando..." : "Confirmar"}
              </button>}
          </DialogTagWrapper>
        </CartItemSummary>
      )}
    </React.Fragment>
  );
};

export default FormCheckout;
