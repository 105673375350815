import axios from 'axios';
import { get_env } from "../helpers/env";

export const getProductFromGamma = async (gamma_code, store) => {
    const API = axios.create({
        baseURL: get_env('REACT_APP_BASE_URL_ORDER_API', '/local-api'),
        headers: {
          "content-type": "application/json"
        }
    })
   
    const data = {}
    const options = {
      params: {
        gamma_code: gamma_code,
        store: store,
      }
    }

    const response = await API.post('/gamma/getproductprice', data, options);
    
    return response
}


