import React, { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { Form, Row } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

//UI
import StyledForm from "../UI/Forms/StyledForm";
import DialogTagWrapper from "../UI/DialogBox/DialogTagWrapper";

//Hooks
import useInput from "../../hooks/use-input";

//Helpers
import { validateEmail } from "../../helpers/string";

//Services
import FeedbackFailed from "../UI/Forms/FeedbackFailed";

//Locale picker
moment.locale("pt-br");

const PersonalInformation = (props) => {
  const userInfo = props.userInfo;
  const userFirstName = userInfo !== null ? userInfo.firstname : '';
  const userLastName = userInfo !== null ? userInfo.lastname : '';
  const userEmail = userInfo !== null ? userInfo.email : '';
  let userPhone = userInfo !== null ? userInfo.phone_number : '';

  if (userPhone == null) userPhone = '';
  else userPhone = userPhone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');

  const [loadRequest, setLoadRequest] = useState(false);

  //Phone input
  const [phone, setPhone] = useState(userPhone);
  const [isTouchedPhone, setIsTouchedPhone] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  const confirmOrder = () => {
      if (!isValidForm()) return false;
      let formattedPhone = phone;
      if (!formattedPhone.startsWith("+")) {
          formattedPhone = countryCode + formattedPhone;
      }
      formattedPhone = "+" + formattedPhone.replace(/[^\d]/g, "");
      const orderData = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: formattedPhone,
      };

      props.setUserInfo(orderData);
      props.onConfirmation(3);
  }

  const isValidForm = () => {
    let qtyInvalid = 0;

    if (!phoneValidation(phone)) {
      setIsInvalidPhone(true);
      qtyInvalid += 1;
    }

    if (!onValidateEmailInput(email)) {
      qtyInvalid += 1;
    }

    if (!onValidateFirstNameInput(firstName)) {
      qtyInvalid += 1;
    }

    if (!onValidateLastNameInput(lastName)) {
      qtyInvalid += 1;
    }

    if (qtyInvalid > 0) return false;

    return true;
  };

  const {
    value: email,
    isInvalid: isInvalidEmail,
    onChangeHandler: onChangeEmailHandler,
    onBlurHandler: onBlurEmailHandler,
    onReset: onResetEmailInput,
    onValidate: onValidateEmailInput,
  } = useInput(validateEmail, userEmail);

  const firstNameValidation = (value) => value.length >= 3;
  const {
    value: firstName,
    isInvalid: isInvalidFirstName,
    onChangeHandler: onChangeFirstNameHandler,
    onBlurHandler: onBlurFirstNameHandler,
    onReset: onResetFirstNameInput,
    onValidate: onValidateFirstNameInput,
  } = useInput(firstNameValidation, userFirstName);

  const lastNameValidation = (value) => value.length >= 3;
  const {
    value: lastName,
    isInvalid: isInvalidLastName,
    onChangeHandler: onChangeLastNameHandler,
    onBlurHandler: onBlurLastNameHandler,
    onReset: onResetLastNameInput,
    onValidate: onValidateLastNameInput,
  } = useInput(lastNameValidation, userLastName);

  const country = "BR";
  const countryCode = "+55";

  const phoneValidation = (value) => {
    let phoneValue = value === undefined ? "" : value;
    if (!phoneValue.startsWith("+")) {
        phoneValue = countryCode + phoneValue;
    }
    phoneValue = "+" + phoneValue.replace(/[^\d]/g, "");
    return isValidPhoneNumber(phoneValue);
  };

  const phoneChangeHandler = (value) => {
    if (!isTouchedPhone) setIsTouchedPhone(true);
    setPhone(value);
  };

  const phoneBlurHandler = (e) => {
    const value = e.target.value;

    if (!isTouchedPhone) setIsTouchedPhone(true);

    if (!phoneValidation(value)) {
      setIsInvalidPhone(true);
      return;
    }

    setIsInvalidPhone(false);
  };

  const onResetPhoneInput = () => {
    setPhone("");
    setIsTouchedPhone(false);
    setIsInvalidPhone(false);
  };

  const confirmLastStep = () => {
    props.onConfirmation(3);
  }

  const submitForm = (event) => {
    event.preventDefault();
    return false;
  };
  

  return (
    
    <React.Fragment>
      <StyledForm onSubmit={submitForm}>
        <h6 className="title-form"><b>Informação Pessoal</b></h6>
        <div className="steps" id="steps">
          <span data-step="1" className="active"></span>
          <div className="active"></div>
          <span data-step="2" className="active"></span>
          <div></div>
          <span data-step="3"></span>
        </div>
        <Row className="mb-3">
          <Form.Group controlId="floatingInputEmail">
            <Form.Label>E-mail <span className="text-primary">*</span></Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={onChangeEmailHandler}
              onBlur={onBlurEmailHandler}
              isInvalid={isInvalidEmail}
              placeholder="seuemail@provedor.com.br"
            />
            {isInvalidEmail && (
              <FeedbackFailed>
                Por favor preencha com um e-mail válido.
              </FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group controlId="floatingInputFirstName">
            <Form.Label>Primeiro nome <span className="text-primary">*</span></Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              onChange={onChangeFirstNameHandler}
              onBlur={onBlurFirstNameHandler}
              isInvalid={isInvalidFirstName}
              placeholder="Seu primeiro nome"
            />
            {isInvalidFirstName && (
              <FeedbackFailed>Por favor preencha com um nome.</FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group controlId="floatingInputLastName">
            <Form.Label>Último nome <span className="text-primary">*</span></Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={onChangeLastNameHandler}
              onBlur={onBlurLastNameHandler}
              isInvalid={isInvalidLastName}
              placeholder="Seu último nome"
            />
            {isInvalidLastName && (
              <FeedbackFailed>
                Por favor preencha com um sobrenome.
              </FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group controlId="floatingInputPhone">
            <Form.Label>Telefone <span className="text-primary">*</span></Form.Label>
            <PhoneInput
              defaultCountry={country}
              value={phone}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              placeholder="(00) 00000-0000"
              className={
                isInvalidPhone ? "form-control is-invalid" : "form-control"
              }
            />
            {isInvalidPhone && (
              <FeedbackFailed>
                Por favor preencha com um telefone válido.
              </FeedbackFailed>
            )}
          </Form.Group>
        </Row>
        </StyledForm>
        <DialogTagWrapper>
            <button
              className="btn btn-outline-secondary mt-2"
              onClick={props.onClose}
            >
              Voltar
            </button>
            <button
              className="btn btn-secondary mt-2 w-100"
              onClick={confirmOrder}
            >
              Confirmar
            </button>
          </DialogTagWrapper>
    </React.Fragment>
  );
};

export default PersonalInformation;
