import React, {useState, useContext, useEffect, useCallback} from "react";
import styled from "styled-components";
import XMLParser from 'react-xml-parser';

// Css
import "./App.scss";

// Services
import {getCurrency, getProduct} from "./services/product";
import {getProductFromGamma} from "./services/gamma";
import { getProductPrecifica } from "./services/precifica";

// Context
import ManageWindowsContext from "./store/manageWindowsContext";
import NotificationContext from "./store/notificationContext";
import CartContext from "./store/cartContext";

// Components
import BarcodeReader from "./components/BarcodeReader/BarcodeReader";
import Notification from "./components/UI/Notification";
import DialogProduct from "./components/Dialog/DialogProduct";
import Sidebar from "./components/Sidebar/Sidebar";
import CartWrapper from "./components/Cart/CartWrapper";
import CheckoutWrapper from "./components/Checkout/CheckoutWrapper";
import {getStoreFromUrl} from "./helpers/http";
import {setLocalStorage, getLocalStorage} from "./helpers/storage";
import {get_env_percent} from "./helpers/env";

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #000;
`;

const NotificationWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: 4rem;
  top: 0.65rem;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   width: 100vw;
//   background: #000;
// `;


const App = (props) => {
    const cartCtx = useContext(CartContext);
    const manageWindowCtx = useContext(ManageWindowsContext);
    const notificationCtx = useContext(NotificationContext);

    const storeFromUrl = getStoreFromUrl();
    const cardElement = React.createRef();
    const cartKey = "cart";

    const [store, setStore] = useState(storeFromUrl);
    const [product, setProduct] = useState({});
    const [isOutOfStock, setIsOutOfStock] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [showWaitMoreItems, setShowWaitMoreItems] = useState(false);
    const [ean, setEan] = useState(0);
    const [salesmanCode, setSalesmanCode] = useState("");    
    const [isLoading, setIsLoading] = useState(false);
    const [productPrecificaPrice, setProductPrecificaPrice] = useState(0);
    
    if (!store && storeFromUrl) setStore(storeFromUrl);

    const openPriceDialog = () => manageWindowCtx.openClosePriceDialog(true);

    const isProductOutOfStock = (productData, storeData) => {
        setIsOutOfStock(false);

        //temporarily disabled
        /*if (productData.hasOwnProperty("stores")) {
          const storeFormatted = storeData.toUpperCase();

          if (!productData["stores"].hasOwnProperty(storeFormatted)) return;

          if (parseInt(productData["stores"][storeFormatted]["stock"]) === 0) {
            setIsOutOfStock(true);
            return;
          }

          setIsOutOfStock(false);
        }*/
    };

    const changeProduct = (productData) => {
        setProduct(productData);
        isProductOutOfStock(productData, store);
    };

    const extractProductPricesFromGammaXML = (gamma_response) => {
        try{
            let xml_text = gamma_response['data']['xml_response']
            let xml = new XMLParser().parseFromString(xml_text); 
            let price = xml.getElementsByTagName('PS-PRECIO-VTA')
            let special_price = xml.getElementsByTagName('PS-PRECIO-OFER')

            return {
                'price': parseFloat(price[0].value),
                'special_price': parseFloat(special_price[0].value)
            }
        }
        catch (error){
            console.log('Error to extract prices from product xml:', error);
            return null
        }
    }

    const alterPricesInProductObject = (product, store, updatedPrices) => {
        product['stores'][store]['price'] = updatedPrices['price']
        product['stores'][store]['special_price'] = updatedPrices['special_price'] > 0 ? updatedPrices['special_price'] : null
    }

    const getProductByEan = async (ean) => {
        setEan(ean)
        setProductPrecificaPrice(0);
        setShowWaitMoreItems(false);
        setIsLoading(true);
        
        const res = await getProduct(ean);

        if ('gamma_code' in res.data){
            const gamma_response = await getProductFromGamma(res.data['gamma_code'], store);
            const prices = extractProductPricesFromGammaXML(gamma_response);
            if(prices){
                alterPricesInProductObject(res.data, store, prices);
            }
        }
        
        setIsLoading(false);
        
        changeProduct(res.data);
        
        if (res.data && res.data.gamma_code)
            getCompareValue(res.data.gamma_code);
        
        if (!manageWindowCtx.showPriceDialog && Object.keys(res.data).length > 0) {
            openPriceDialog();
            return;
        }

    };

    const getCompareValue = async (gamma_code) => {        
        try {
  
          const resp = await getProductPrecifica(gamma_code);
          
          if (resp.status === 200){
            const data = await resp.data;
            setProductPrecificaPrice(data.price);
          }
  
        } catch (error) {
          console.log('Falha ao comparar o produto', error);
        }
      };

    const registerSalesmanCode = async (salesmanCode) => {
        let value = salesmanCode;
        if (isNaN(value)) value = "";
        setLocalStorage('salesman-code', value, 120)
        setSalesmanCode(value);
    }

    const onCloseDialog = () => manageWindowCtx.openClosePriceDialog(false);

    const loadCartByLocalStorage = useCallback(() => cartCtx.loadCartByLocalStorage(), [cartCtx])

    const onCloseCart = () => {
        setShowWaitMoreItems(false);
        manageWindowCtx.openCloseCart(false);
    }

    const onAddItem = (item) => {
        setShowWaitMoreItems(true);
        cartCtx.addItem(item);
        manageWindowCtx.openCloseCart(true);
    }

    useEffect(() => {
        if (!store) setStore(getLocalStorage('default-store'))
        if (currency === null && store) getCurrency(store).then((resp) => {            
            setCurrency(resp.data);
        });

        const dataOnLocalStorage = localStorage.getItem(cartKey);

        if (dataOnLocalStorage !== null) {
            loadCartByLocalStorage();
        }

        setSalesmanCode(getLocalStorage('salesman-code'));

        let redirectPage = getLocalStorage('redirect-page')
        if (redirectPage === 'checkout') {
            manageWindowCtx.openCloseCheckout(true)
        }
    }, [currency, store, loadCartByLocalStorage])


    let showCart = manageWindowCtx.showCart;
    let showCheckout = manageWindowCtx.showCheckout;
    let showCheckoutConfirmation = manageWindowCtx.showCheckoutConfirmation;

    return (
        <AppWrapper>
            {currency !== null && store !== null && (
                <React.Fragment>
                    <BarcodeReader
                        onGetProductByEan={getProductByEan}
                        onGetSalesmanCode={registerSalesmanCode}
                        onShowPriceDialog={openPriceDialog}
                        store={store}
                    />

                    <DialogProduct
                        showPriceDialog={manageWindowCtx.showPriceDialog}
                        onCloseDialog={onCloseDialog}
                        isOutOfStock={isOutOfStock}
                        product={product}
                        store={store}
                        currency={currency}
                        cardElementRef={cardElement}
                        onAddItem={onAddItem}
                        ean={ean}
                        productPrecificaPrice={productPrecificaPrice}
                    />

                <Sidebar store={store} manageWindowCtx={manageWindowCtx} cartCtx={cartCtx} salesmanCode={salesmanCode}/>

                {showCart && (
                    <CartWrapper
                        store={store}
                        items={cartCtx.items}
                        showWaitMoreItems={showWaitMoreItems}
                        onAddItem={cartCtx.addItem}
                        onRemoveItem={cartCtx.removeItem}
                        onCancelItem={cartCtx.cancelItem}
                        onCloseCart={onCloseCart}
                        onSetSkuItem={cartCtx.setSkuItem}
                        onOpenCheckout={() => manageWindowCtx.openCloseCheckout(true)}
                        onLoadCartByLocalStorage={cartCtx.loadCartByLocalStorage}
                        onSalesmanCodeChange={registerSalesmanCode}
                    />
                )}

                {(showCheckout || showCheckoutConfirmation) && (
                    <CheckoutWrapper
                        showCheckout={showCheckout}
                        showCheckoutConfirmation={showCheckoutConfirmation}
                        items={cartCtx.items}
                        onOpenCart={() => manageWindowCtx.openCloseCart(true)}
                        onOpenCheckoutConfirmation={() => manageWindowCtx.openCloseCheckoutConfirmation(true)}
                        onCloseCheckoutConfirmation={() => manageWindowCtx.openCloseCheckoutConfirmation(false)}
                        onClearCart={cartCtx.clearCart}
                    />
                )}

                {Object.keys(notificationCtx.notifications).length > 0 && (
                    <NotificationWrapper>
                        {Object.keys(notificationCtx.notifications).map((key) => {
                            const data = notificationCtx.notifications[key];
                            const {message, onClose, ...notificationProps} = data;

                            return (
                                <Notification key={key} onClose={onClose} {...notificationProps}>
                                    {message}
                                </Notification>
                            );
                        })}
                    </NotificationWrapper>
                )}
            </React.Fragment>
        )}
        {((currency === null && store !== null) || (isLoading === true))  && (
            <div className="fullscreen-loading">
                {" "}
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        )}
        {store === null && (
            <div style={{color: "#FFF"}}>
                {" "}
                Loja não identificada{" "}
            </div>
        )}
    </AppWrapper>
    );
};

export default App;
