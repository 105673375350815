
export const httpResponse = (body = null, status = 200, statusText = "") => {
    const init = {
      status: status,
    };
  
    if (statusText.length > 0) {
      Object.assign(init, { statusText: statusText });
    }
  
    return new Response(body, init);
  };
  
  export const badRequest = (message) => httpResponse(null, 400, message);
  
  export const serverErrorRequest = (message) => httpResponse(null, 500, message);
  
  export const successRequest = (body) => httpResponse(body, 200);

  export const getStoreFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let store = urlParams.get("store");

    if (store) return store.toUpperCase();

    return store;
  }

  export const getPickupStoresFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let pickupStores = urlParams.get("pickup-stores");
    let finalPickupStores = []
    
    /*if (pickupStores){
      pickupStores = pickupStores.split(',');
      finalPickupStores = pickupStores.map(store => store.toUpperCase());
      return finalPickupStores;
    }*/
    
    return null;
  }