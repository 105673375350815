import React from 'react';

const manageWindowsContext = React.createContext({
    showPriceDialog: false,
    showQuota: false,
    showCart: false,
    showCheckout: false,
    showCheckoutConfirmation: false,
    showBarcodeInput: false,
    showAddToCart: false,
    showAreYouSure: false,
    openClosePriceDialog: (boolValue) => {},
    openCloseQuota: (boolValue) => {},
    openCloseCart: (boolValue) => {},
    openCloseCheckout: (boolValue) => {},
    openCloseCheckoutConfirmation: (boolValue) => {},
    openCloseBarcodeInput: (boolValue) => {},
    openCloseAddToCart: (boolValue) => {},
    openCloseAreYouSure: (boolValue) => {}
});

export default manageWindowsContext;