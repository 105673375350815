import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

// Components
import Box from "./Box";

const slideDown = keyframes`
    from {
        opacity: 0;
        transform: translateY(-3.8rem);
    }

    to {
        opacity: 1;
        transform: translateY(0.65rem);
    }
`;

const slideUp = keyframes`
    from {
        opacity: 1;
        transform: translateY(0.65rem);
    }

    to {
        opacity: 0;
        transform: translateY(-3.8rem);
    }
`;

const BoxAnimated = styled(Box)`
  &.open-notification {
    animation: ${slideDown} 900ms ease-in;
  }

  &.close-notification {
    animation: ${slideUp} 900ms ease-in-out;
  }
`;

const Notification = (props) => {
  const [isClosed, setIsClosed] = useState(false);
  const {onClose, ...notificationProps} = props

  const closeHandler = () => {
    setIsClosed(true);

    setTimeout(() => props.onClose(), 901);
  };

  let classes = "open-notification";

  if (isClosed) {
    classes = "close-notification";
  }

  return (
    <BoxAnimated
      onClose={closeHandler}
      className={classes}
      {...notificationProps}
    >
      {props.children}
    </BoxAnimated>
  );
};

export default Notification;
