import React from "react";
import './salesman.css'

import { ReactComponent as IconSalesman } from "../../assets/images/icons/icon-salesman.svg";

const Salesman = ({salesmanCode}) => {
    function localStorageExpires() {
        var toRemove = [],                      // Itens para serem removidos
            currentDate = new Date().getTime(); // Data atual em milissegundos

        var i, j;

        for (i = 0, j = localStorage.length; i < j; i++) {
            var key = localStorage.key(i),
                value = localStorage.getItem(key);

            // Verifica se o formato do item para evitar conflitar com outras aplicações
            if (value && value[0] === "{" && value.slice(-1) === "}") {

                // Decodifica de volta para JSON
                var current = JSON.parse(value);

                // Checa a chave expires do item especifico se for mais antigo que a data atual ele salva no array
                if (current.expires && current.expires <= currentDate) {
                    toRemove.push(key);
                }
            }
        }

        // Remove itens que já passaram do tempo
        // Se remover no primeiro loop isto poderia afetar a ordem,
        // pois quando se remove um item geralmente o objeto ou array são reordenados
        for (i = toRemove.length - 1; i >= 0; i--) {
            localStorage.removeItem(toRemove[i]);
        }
    }

    localStorageExpires();//Auto executa a limpeza

    return(
        <>
            {salesmanCode &&
                <div className="font_custom">
                    <IconSalesman /> &nbsp;
                    {salesmanCode}
                </div>
            }
        </>
    )


}
export default Salesman;
