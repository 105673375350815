import React, {useState, useRef, useEffect } from "react";

import { Video, Container, ScanMarker } from './styles'
import ReadingLine from "../UI/Barcode/ReadingLine";


import { useTranslation } from 'react-i18next';


import { Codebutton } from "./styles";
import BarcodeInput from "./BarcodeInput";
import SalesmanInput from "./SalesmanInput";
import DialogButton from "../UI/Buttons/DialogButton";
import { airportStores } from "../../services/order";
import { Barcode, ScanSettings } from "scandit-sdk";
import ScanditBarcodeScanner from "scandit-sdk-react";
import { get_env } from '../../helpers/env';


const BarcodeReader = (props) => {
    const { t, i18n } = useTranslation();

    var backgroundbarcode = useRef(null);

    const videoElement = React.createRef();
    const licenseKey = get_env('REACT_APP_SCANDIT_LICENCE_KEY', '');
 
    
    useEffect(() => {
        backgroundbarcode = backgroundbarcode.current;
    });
    
    const [barData, setBarData] = useState(null);
    const [showBarcodeInput, setShowBarcodeInput] = useState(false);
    const [showSalesmanInput, setShowSalesmanInput] = useState(false);
    const ProductButton = DialogButton;
    const SalesmanButton = DialogButton;
    const findStore = airportStores[props.store]
    let reserveEnabled
    if (findStore === undefined) {
        reserveEnabled = airportStores["default"]["reserve_enabled"];
       
    } else{
        reserveEnabled = airportStores[props.store]["reserve_enabled"];
    }

   
    let audioCtx
    function beep(duration, frequency, volume, type, callback) {
        audioCtx  = new AudioContext();
        var oscillator = audioCtx.createOscillator();
        var gainNode = audioCtx.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);

        if (volume){gainNode.gain.value = volume;}
        if (frequency){oscillator.frequency.value = frequency;}
        oscillator.type = type || 'square' ;
        if (callback){oscillator.onended = callback;}

        oscillator.start(audioCtx.currentTime);
        oscillator.stop(audioCtx.currentTime + ((duration || 50) / 1000));
    };
    
    const openBarcodeInput = () => {
        backgroundbarcode.classList.toggle("d-none");
        setShowBarcodeInput(true) 
           
    };

    const onCloseBarcodeInput = () => {
        backgroundbarcode.classList.remove("d-none");
        setShowBarcodeInput(false)
        
    };

    const openSalesmanInput = () => {
        backgroundbarcode.classList.toggle("d-none");
        setShowSalesmanInput(true)
    };

    const closeSalesmanInput = () => {
        backgroundbarcode.classList.remove("d-none");
        setShowSalesmanInput(false)
    };

    const onScan = (scanResult) => {
        const code = scanResult.barcodes[0].data;
        if (code.startsWith("0") && code.length === 7) {
            backgroundbarcode.classList.remove("d-none");
            props.onGetSalesmanCode(code);
        }
        else if (!barData || barData !== code) {
            backgroundbarcode.classList.remove("d-none");
            setBarData(code);
            props.onGetProductByEan(code);
        }
        else if (barData === code) {
            backgroundbarcode.classList.remove("d-none")
            props.onShowPriceDialog();
        }
        window.gtag("event", "type_product", {});
    };

    const onScanInputData = (scanResult) => {
        const code = scanResult.text;
        if (code.startsWith("0") && code.length === 7) {
            backgroundbarcode.classList.remove("d-none");
            beep();
            props.onGetSalesmanCode(code);
        }
        else if (!barData || barData !== code) {
            backgroundbarcode.classList.remove("d-none");
            setBarData(code);
            beep();
            props.onGetProductByEan(code);
        }
        else if (barData === code) {
            backgroundbarcode.classList.remove("d-none")
            props.onShowPriceDialog();
        }
        window.gtag("event", "type_product", {});
    };


    const getScanSettings = () => {
        return new ScanSettings({
            enabledSymbologies: [
                Barcode.Symbology.CODE128,
                Barcode.Symbology.EAN8,
                Barcode.Symbology.EAN13,
            ],
            codeDuplicateFilter: 1000,
        });
    };

    useEffect(() => {
        // Wait 5 seconds before send gtag event "session_start"
        setTimeout(() => {
            var eventSessionStart = window.dataLayer?.findIndex(x => x[1] === "session_start");
            if (eventSessionStart < 0)  {
                window.gtag("event", "session_start", {});
            }
        }, 5000);
    }, []);

    return (
        <>
        <Video id="video"
            ref={videoElement} >

          <div
            >
            <ScanditBarcodeScanner
                licenseKey={licenseKey}
                engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build"
                guiStyle="none" // laser || none || viewfinder
                codeDuplicateFilter={'8000'}
                visible={true}
                playSoundOnScan={true}
                vibrateOnScan={true}
                onScan={onScan}
                scanSettings={getScanSettings()}
                currentResolution={{height: window.innerHeight}}
                videoFit={'cover'}
            />
        </div>
        </Video>
        <Container>
            <div className="label">
                <span>{t('Posicione o código de barras no centro da tela')}</span>
            </div>

            <ScanMarker>
                <ReadingLine />
            </ScanMarker>
        </Container>
        
        {showSalesmanInput && <SalesmanInput
            onCloseSalesmanInput={closeSalesmanInput}
            onGetSalesmanCode={props.onGetSalesmanCode}
        />}
        {showBarcodeInput && <BarcodeInput
            getProductByEan={onScanInputData}
            onCloseBarcodeInput={onCloseBarcodeInput}
        />}


        <Codebutton ref={backgroundbarcode} className="codeButton">
        
            <div className="label-button">
                <span>{t('Se preferir, digite o código')}:</span>
            </div>
            <div className="group-button">
                <ProductButton
                    className="red"
                    onClick={openBarcodeInput}
                >{t('Produto')}
                </ProductButton>
                {reserveEnabled &&
                    <SalesmanButton
                        className="red"
                        onClick={openSalesmanInput}
                    >
                        Vendedor
                    </SalesmanButton>
                }
            </div>
        </Codebutton>
        </>
    )
};

export default BarcodeReader;