import styled from "styled-components";

import RoudedButton from "./RoundedButton";

const CallButton = styled(RoudedButton)`
  background-color: ${(props) => props.backgroundColor || "#FFFFFF"};
  border: 1px solid ${(props) => props.borderColor || "#FFFFFF"};
  z-index: 10;
  color: ${(props) => props.textColor || "#FA0202"};
  padding: 0;
`;

export default CallButton;