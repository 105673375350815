import { useState } from "react";

const useInput = (validate, initialValue = "") => {
  const [input, setInput] = useState(initialValue);
  const [isTouched, setIsTouched] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const isValid = (value) => validate(value);

  const inputChangeHandler = (e) => {
    const value = e.target.value;

    setInput(value);

    if (!isTouched) setIsTouched(true);

    setIsInvalid(false);
  };

  const inputBlurHandler = (e) => {
    const value = e.target.value;

    if (!isTouched) setIsTouched(true);

    validateInput(value);
  };

  const validateInput = (value) => {
    if (!isValid(value)) {
      setIsInvalid(true);
      return false;
    }

    setIsInvalid(false);
    return true;
  };

  const resetInput = () => {
    setInput(initialValue);
    setIsTouched(false);
    setIsInvalid(false);
  };

  return {
    value: input,
    setInput,
    isInvalid,
    isTouched,
    onChangeHandler: inputChangeHandler,
    onBlurHandler: inputBlurHandler,
    onReset: resetInput,
    onValidate: validateInput,
  };
};

export default useInput;