import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

// Helpers
import { combineHostEndpoint } from "../../helpers/string";
import styled from "styled-components";
import NumberFormat from "react-number-format";

// Components
import DialogWrapperWithRef from "../UI/DialogBox/DialogWrapperWithRef";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import DialogTagOutOfStock from "../UI/DialogBox/DialogTag";
import DialogTagWrapper from "../UI/DialogBox/DialogTagWrapper";
import DialogPriceWrapper from "../Dialog/DialogPriceWrapper";
import ReserveButton from "../UI/Buttons/DialogButton";
import ReadMore from "../ReadMore/ReadMode";
import { isAddToCartAvailable,  airportStores} from "../../services/order";
import { ReactComponent as IconBasket } from "../../assets/images/icons/basket.svg";
import { ButtonGroup, Button } from "react-bootstrap";
import Modal from '../Modal/ReadProductModal';

// Icons
import { BsPlusLg, BsDashLg } from "react-icons/bs";

// Assets
import NoImageImg from "../../assets/images/no-image.jpeg";

const PERC_MIN_SHOW_PRICE_PRECIFICA = 5;

const ControlButtonQuantity = styled.div`
    .controls {
      margin-top: 15px;
      display: flex;
      align-items: center;
      
      p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 700;
        margin-right: 10px!important;
      }
    }
  
    .btn-group {
      align-items: center;
      justify-content: center;
  
      .btn-outline-secondary {
        background-color: #fff;
        color: #252525;
        border: none;
        font-size: 16px;
        font-weight: bold;
  
        &:first-of-type,
        &:last-of-type {
          border-radius: 50px;
          background-color: #E6E6E6;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
        }
  
        svg {
          fill: var(--bs-dark);
        }
  
        &:focus {
          box-shadow: unset;
        }
      }
    }
    `;

const DialogProduct = React.forwardRef(
  (
    { showPriceDialog, onCloseDialog, isOutOfStock, product, store, currency, onAddItem, ean, productPrecificaPrice },
    cardElementRef
  ) => {
    let [totalAmount, setTotalAmount] = useState(1);
    let [priceBr, setPriceBr] = useState(null);
    const { t, i18n } = useTranslation();
    
    const closeDialog = () => {
      setTotalAmount(1);      
      setPriceBr(null);
      onCloseDialog();
    }

    const getProductDescription = () => {
      let language = i18n.language

      if (language.search('es') !== -1){
        return product.description_es;
      }else if (language.search('pt') !== -1){
        return product.description_pt;
      }else if (language.search('en') !== -1){
        return product.description_en;
      }

    };

    const getStoreItem = () => {
      const stores = product.stores;

      if (store in stores) return stores[store];

      return {};
    };

    const [showModal, setShowModal] = useState(false);

    const hasStore = () => {
      const stores = product.stores;

      if (stores === undefined) {
        return false;
      }

      return Object.keys(getStoreItem()).length > 0 ? true : false;
    };

    const findStore = airportStores[store]
    let reserveEnabled;
    let brl_value_enabled;

    if (findStore === undefined) {
      reserveEnabled = airportStores["default"]["reserve_enabled"];
      brl_value_enabled = true;
    } else{
      reserveEnabled = airportStores[store]["reserve_enabled"];
      if ("brl_value_enabled" in airportStores[store]){
        brl_value_enabled = airportStores[store]["brl_value_enabled"]
      }else{
        brl_value_enabled = true;
      }
      
    }

    const addItem = () => {
      setTotalAmount(++totalAmount);
      window.gtag("event", "quantity", {});
    }

    const closeModal = () => {
      addCartItem();
      setShowModal(false);
      closeDialog();
    }

    const removeItem = () => {
      setTotalAmount(--totalAmount);
      window.gtag("event", "quantity", {});
    }

    const addCartItem = () => {
      setShowModal(false);
      if (Object.keys(product).length > 0) {
        const storeItem = getStoreItem();
        let salesmanCode = localStorage.getItem('salesman-code');
        if (salesmanCode && salesmanCode[0] === "{" && salesmanCode.slice(-1) === "}") {
            salesmanCode = JSON.parse(salesmanCode).value;
        }

        setTotalAmount(1);

        onAddItem({
          gammaCode: product.gamma_code,
          sapCode: product.sap_code,
          amount: totalAmount,
          name: product.name,
          eans: product.eans,
          sku: product.cod_sku || null,
          image: product.image,
          price: storeItem.price,
          specialPrice: storeItem.special_price || 0,
          categoryCode: product.cod_category_dcis,
          currency: storeItem.currency,
          salesmanCode: salesmanCode,
          brandName: product.brand_name,
          subBrandName: product.sub_brand_name,
        });
      }
    };

    const isValidProduct = product && !product.error && hasStore();

    let imageUrl;
    if (product !== null && product !== undefined && product.image !== null && product.image !== undefined && product.image.base_url !== null && product.image.base_url !== undefined) {      
      imageUrl = combineHostEndpoint(product.image.base_url, ['image/', 'upload/', 'c_pad,f_auto,h_350,w_350/', product.image.default_images[0]]);
    }
    
    const changePriceBr = (value) => {      
      setPriceBr(value);
    }

    const getPercentageCompetitor = () => {      
      if (productPrecificaPrice > 0 && priceBr > 0){        
        const perc = (100 - (100 * priceBr) / productPrecificaPrice)
        return perc >= PERC_MIN_SHOW_PRICE_PRECIFICA ? perc : 0;
      }
      return 0;
    }

    const getPriceConverted = (value, convertTo) => {
      let i = currency.findIndex((x) => x.currency_to === "BRL");
      const rate = currency[i].rate;
      if (convertTo === "USD") return value / rate;
      else return value * rate;
    };

    const getUsdPrice = () => {
      try {
        const itemData = getStoreItem();
        const price = itemData.price;
        const currency = itemData.currency;
  
        if (currency === "USD") return price;
        else return getPriceConverted(price, "USD");
      } catch (error) {
        return 0;
      }
    };

    useEffect(() => {
      if(showPriceDialog && product && Object.keys(product).length > 0 && product.name !== undefined && product.name !== null) {
        window.gtag("event", "view_item", {
          currency: getStoreItem().currency,
          value: getUsdPrice(),
          items: {
            item_id: product.sap_code,
            item_name: product.name
          }
        });
      }
    }, [showPriceDialog, product]);

    return (
      <DialogWrapperWithRef ref={cardElementRef}>                
        {showPriceDialog && product && (
          <DialogCard            
            hiddenCloseButton="true"
            onClose={onCloseDialog}
            outOfStock={isValidProduct && isOutOfStock}
            hideHeander={true}
            
          >
            {showPriceDialog && !isValidProduct && (
              <div>
                <p className="pt-4">{t('Produto não encontrado')}: {ean}</p>
                <DialogTagWrapper>
                  <button className="btn btn-outline-secondary" onClick={closeDialog}>
                    {t('Voltar')}
                  </button>
                </DialogTagWrapper>
              </div>
              )
            }

            {!product.error && hasStore() && (
              <DialogCardBody>
                {/* Imagem */}
                <div className="row">
                  <div className="col text-center">
                      {imageUrl ? (
                      <img src={imageUrl} alt={product.name} />
                    ) : (
                      <img src={NoImageImg} alt="Sem imagem" />
                    )}    
                  </div>
                </div>                
                <div className="my-2 product-details-title">
                  <p><b>{product.brand_name}</b></p>
                  <p>{product.name}</p>
                </div>

                <DialogPriceWrapper
                  storeItem={getStoreItem()}
                  hasStore={hasStore()}
                  currencies={currency}
                  reserveEnabled={reserveEnabled}     
                  changePriceBr={changePriceBr}
                  brl_value_enabled={brl_value_enabled}
                  product={product}
                />

                <div className="mt-1 current-price-legend">
                  <p>
                    * {t('Será aplicada a taxa de câmbio vigente no dia da compra na loja.')}
                    {reserveEnabled && (
                      <span> O preço em destaque aplica-se exclusivamente à reserva.</span>
                    )}
                  </p>
                </div>
                {reserveEnabled && (
                  <ControlButtonQuantity>
                    <div className="controls">
                      <p>Qtd</p>
                      <ButtonGroup>
                        <Button variant="outline-secondary" onClick={removeItem}>
                          <BsDashLg />
                        </Button>
                        <Button variant="outline-secondary" disabled>
                          {totalAmount}
                        </Button>
                        <Button variant="outline-secondary" onClick={addItem}>
                          <BsPlusLg />
                        </Button>
                      </ButtonGroup>
                    </div>
                  </ControlButtonQuantity>
                )}

                {reserveEnabled && (getPercentageCompetitor() > 0) && (
                  <div className="mt-3 price-comparation">
                    <p><NumberFormat
                          value={getPercentageCompetitor()}
                          displayType={"text"}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          fixedDecimalScale={true}
                          decimalScale={0}
                          prefix={""}
                        />% mais barato que a concorrência</p>
                  </div>
                )}

                {product.description !== null && product.description !== undefined && getProductDescription() && (
                  <div className="mt-3">
                    <p><b>{t('Detalhes')}</b></p>
                    <ReadMore>{getProductDescription()}</ReadMore>
                  </div>
                )}

                <DialogTagWrapper>
                  <button className="btn btn-outline-secondary" onClick={closeDialog}>
                    {t('Voltar')}
                  </button>

                  {isOutOfStock && (
                    <DialogTagOutOfStock>Fora de Estoque</DialogTagOutOfStock>
                  )}
                  {reserveEnabled && !isOutOfStock && isAddToCartAvailable(store) && (
                    <ReserveButton
                      className="btn-secondary w-100"
                      onClick={() => { 
                        setShowModal(true);
                        window.gtag("event", "add_to_cart", {
                          currency: getStoreItem().currency,
                          value: getUsdPrice(),
                          items: {
                            item_id: product.sap_code,
                            item_name: product.name
                          }
                        });
                      }}
                    >
                      Reservar
                      <IconBasket />
                    </ReserveButton>
                  )}
                </DialogTagWrapper>
              </DialogCardBody>
            )}
          </DialogCard>
        )}
        <Modal
            onClose={() => setShowModal(false)}
            show={showModal}
            hideImg={false}
            btnOne={"Ir para o carrinho"}
            onclickOne={addCartItem}
            classBtnOne={"btn btn-secondary w-100"}
            btnTwo={"Continuar comprando"}
            classBtnTwo={"btn btn-outline-secondary w-100"}
            onclickTwo={closeModal}
          >
        </Modal>
      </DialogWrapperWithRef>
    );
  }
);

export default DialogProduct;
