
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
  
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    if (isReadMore) window.gtag("event", "more_item", {});
  };
  const { t } = useTranslation();

  return (
    <p className="product-description">
      {(isReadMore && text !== null && text !== undefined) ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore}>
        {(isReadMore && text !== null && text !== undefined) ? " ... " : ""} <span className="text-underline" role="button">{isReadMore ? "ver mais" : " ver menos"}</span>
      </span>
    </p>
  );
};
  
export default ReadMore;