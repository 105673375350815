import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { get_env_percent } from "../../../helpers/env";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 96%;
  padding-bottom: 3rem;
  border-top: 1px solid #fff;
  font-size: 14px;
  margin-top: 15px;

  .summary-lines {
    display: flex;
    justify-content: space-between;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .error-message {
    color: #e33338;
    margin-top: 0.75rem;
    font-weight: bold;
  }

  button {
    &.btn-outline-secondary {
      border-radius: 0;
      border: 2px solid #252525;
    }

    &.btn-secondary {
      border-radius: 0;
      width: 100%;
      border: 2px solid #252525;
    }
  }

  .line {
    color: #C2C2C2;
  }
`;

const SHOW_RC_DISCOUNT = true; // 15% discount on R&C
const PERCENT_DISCOUNT = (get_env_percent('REACT_APP_DISCOUNT_VALUE', ''))

const CartItemSummary = (props) => {
  let subtotal = 0;
  let discount = 0;
  let currency = props.items[0].currency;

  for (const item of props.items) {
    let price = item.price;
    const specialPrice = item.specialPrice;
    let discount20 = 0;
    let priceWith20Discount = 0;

    if (SHOW_RC_DISCOUNT && item.categoryCode !== "010") { // Avoid tobacco discount
      discount20 = price * PERCENT_DISCOUNT;
      priceWith20Discount = price - discount20;
      priceWith20Discount = priceWith20Discount < 0 ? 0 : priceWith20Discount;
    }

    if (specialPrice > 0 && specialPrice < priceWith20Discount) {
      price = specialPrice;
      discount20 = 0;
    }

    subtotal += price * item.amount;
    discount += discount20 * item.amount;
  }

  const prefixNumber = currency === "USD" ? "US$ " : "R$ ";
  const decimalSeparator = currency === "USD" ? "." : ",";
  const thousandSeparator = currency === "USD" ? "," : ".";
  const total = subtotal - discount;

  return (
    <Wrapper>
      {discount > 0 && (
        <React.Fragment>
          <hr className="line m-1"></hr>
          <div className="summary-lines">
            <span>Subtotal</span>
            <NumberFormat
              value={subtotal}
              displayType={"text"}
              decimalSeparator={decimalSeparator}
              thousandSeparator={thousandSeparator}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={prefixNumber}
              className="value"
            />
          </div>

          {SHOW_RC_DISCOUNT && (
            <div className="summary-lines">
              <span style={{ width: "50%" }}>
                Desconto total
              </span>
              <NumberFormat
                value={-discount}
                displayType={"text"}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={prefixNumber}
                className="value"
              />
            </div>
          )}
        </React.Fragment>
      )}
      <div className="summary-lines">
        <span className="fw-bold">Total</span>
        <NumberFormat
          value={total}
          displayType={"text"}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={prefixNumber}
          className="value fw-bold"
        />
      </div>
      <hr className="line mt-1"></hr>
     {props.children}
    </Wrapper>
  );
};

export default CartItemSummary;