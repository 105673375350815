export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CANCEL_ITEM = 'CANCEL_ITEM';
export const SET_SKU_ITEM = 'SET_SKU_ITEM';
export const LOAD_CART_BY_LOCAL_STORAGE = 'LOAD_CART_BY_LOCAL_STORAGE';

export const addCartItemAction = (item) => ({
    type: ADD_ITEM,
    payload: item
});

export const removeCartItemAction = (gammaCode) => ({
    type: REMOVE_ITEM,
    payload: gammaCode
})

export const cancelCartItemAction = (gammaCode) => ({
    type: CANCEL_ITEM,
    payload: gammaCode
})

export const setSkuCartItemAction = (gammaCode, sku) => ({
    type: SET_SKU_ITEM,
    payload: {
        gammaCode,
        sku
    }
})
export const loadCartByLocalStorageAction = () => ({
    type: LOAD_CART_BY_LOCAL_STORAGE
});