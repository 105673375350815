import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/notification";

export const initialState = {
  notifications: {},
};

const notificationReducer = (state, { type, payload }) => {
  const notifications = state.notifications;

  switch (type) {
    case ADD_NOTIFICATION:
      const { key, ...data } = payload;

      Object.assign(notifications, {
        [key]: data,
      });

      return {
        ...state,
        notifications,
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: (Object.keys(notifications)
        .filter((key) => key !== payload)).reduce(
            (acc, key) => ({
              ...acc,
              [key]: notifications[key],
            }),
            {}
          )
      };
    default:
      return state;
  }
};

export default notificationReducer;
