import styled from "styled-components";

const DialogTag = styled.div`
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #fff;
  padding: 11px 12px;
  line-height: 1.2;
`;

export default DialogTag;