import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";

// Helpers
import { combineHostEndpoint } from "../../../helpers/string";

// Components
import Box from "../../UI/Box";

// Assets
import NoImageImg from "../../../assets/images/no-image.jpeg"
import { ReactComponent as SalesManIcon } from "../../../assets/images/icons/salesman.svg";

const CartItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -1px;

  .salesmanCode {
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    margin: 15px 0 0 0;
  }

  img {
    max-width: 5em;
    max-height: 5.2em;
  }

  .name {    
    font-weight: 700;    
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
  }

  .brand {
    font-size: 10px;
    font-weight: 400;
  }

  .price-value {
    line-height: 1;
  }

  .no-special-price {
    margin-top: 15px;
  }

  .salesman-code {
    font-size: xx-small;
    font-weight: 100;
  }

  .hasSpecialOffer {
    text-decoration-line: line-through;
    font-size: 12px;
    color: #bebebe;
  }
  .controls {
    margin-top: 3px;
  }

  .btn-group {
    align-items: center;
    justify-content: center;

    .btn-outline-secondary {
      background-color: #fff;
      color: var(--bs-dark);
      border: none;
      font-size: 16px;
      font-weight: bold;

      &:first-of-type,
      &:last-of-type {
        border-radius: 50px;
        background-color: #E6E6E6;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }

      svg {
        fill: var(--bs-dark);
      }

      &:focus {
        box-shadow: unset;
      }
    }
  }
`;

const BoxItem = styled(Box)`
  min-width: 180px;
  // max-height: 12.5rem;
  margin: 0.5rem !important;    
`;

const Wrapper = (props) => {
  const { gammaCode, image, name, price, specialPrice, currency, brandName, salesmanCode } = props.product;
  let imageUrl = "";

  if (Object.keys(image).length > 0 && image.base_url !== null) {
    imageUrl = combineHostEndpoint(image.base_url, ['image/', 'upload/', 'c_pad,f_auto,h_350,w_350/', image.default_images[0]]);
  }

  const cancelItem = () => props.onCloseItem(gammaCode)

  const prefixNumber = currency === "USD" ? "US$ " : "R$ ";
  const decimalSeparator = currency === "USD" ? "." : ",";
  const thousandSeparator = currency === "USD" ? "," : ".";

  return (
    <BoxItem
      backgroundColor="#FFF"
      textColor="#000"
      buttonCloseRounded={false}
      onClose={props.onCloseItem && cancelItem}
    >
      <CartItemWrapper>
        {salesmanCode ? (
          <div className="salesmanCode"><SalesManIcon /> {salesmanCode}</div>
        ) : (
          <div className="salesmanCode">&nbsp;</div>
        )}
        
        {imageUrl ? (
          <img src={imageUrl} alt={name} />
        ) : (
          <img src={NoImageImg} alt="Sem imagem" />
        )}
        <p className="name">{name}</p>
        <p className="brand">{brandName}</p>
        <div
          className={`price-value ${
            specialPrice > 0
              ? "price-value hasSpecialOffer"
              : "no-special-price"
          }`}
        >
          <NumberFormat
            value={price}
            displayType={"text"}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={prefixNumber}
          />
        </div>
        {specialPrice > 0 && (
          <div className="price-value">
            <NumberFormat
              value={specialPrice}
              displayType={"text"}
              decimalSeparator={decimalSeparator}
              thousandSeparator={thousandSeparator}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={prefixNumber}
            />
          </div>
        )}
        {props.children}
      </CartItemWrapper>
    </BoxItem>
  );
};

export default Wrapper;
