import styled from 'styled-components';

const DialogTagWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px 20px;
    width: 100%;
    height: auto;
    background-color: var(--bs-white);
    box-shadow: 0px -4px 4px rgba(0,0,0,0.25);
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 20px;
`;

export default DialogTagWrapper;