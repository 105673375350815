import React from 'react';

const CartContext = React.createContext({
    items: [],
    addItem: (item) => {},
    removeItem: (gammaCode) => {},
    cancelItem: (gammaCode) => {},
    setSkuItem: (gammaCode, sku) => {},
    clearCart: () => {},
    loadCartByLocalStorage: () => {}
})

export default CartContext;