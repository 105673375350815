import axios from 'axios';
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";

import BrasilDutyFreeLogo from "../assets/images/brasil-dutyfree.png";

import RCServiceLogo from "../assets/images/r-c_service.png";
import DufryIcon from "../assets/images/dufry-icon-150-150.png";

//Helpers
import { validateEmail } from "../helpers/string";
import { get_env } from "../helpers/env";

const API = axios.create({
  baseURL: get_env('REACT_APP_BASE_URL_ORDER_API', '/local-api'),
  headers: {
    "content-type": "application/json"
  }
})

const _makeRequest = async (endpoint, method, data = null, options = null) => {
  try {
    let res;
    const methodFormated = method.toUpperCase()

    if (methodFormated === "POST")
      res = await API.post(endpoint, data, options);

    if (methodFormated === "GET")
      res = await API.get(endpoint, options);

    if (res.status !== 200)
      throw new Error(res.statusText);

    const resData = res.data;

    if (Object.keys(resData).includes('detail'))
      throw new Error(resData['detail']);

    return resData;
  } catch (error) {
    return { detail: error.message }
  }
}

//Embarque = departure = outbound
//Desembarque = arrival = inbound
export const airportStores = {
  BRT070: {
    terminal_id: 8,
    airport_id: 210,
    airport: "GRU",
    terminal: "T3",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 653

  },
  BRT072: {
    terminal_id: 8,
    airport_id: 210,
    airport: "GRU",
    terminal: "T3",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 652
  },
  BRT049: {
    terminal_id: 7,
    airport_id: 210,
    airport: "GRU",
    terminal: "T2",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 649
  },
  BRT050: {
    terminal_id: 7,
    airport_id: 210,
    airport: "GRU",
    terminal: "T2",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 648

  },
  BDT111: {
    terminal_id: 1,
    airport_id: 208,
    airport: "GIG",
    terminal: "T2",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 641

  },
  BDT112: {
    terminal_id: 1,
    airport_id: 208,
    airport: "GIG",
    terminal: "T2",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 640,
    min_booking_period: 15

  },
  BDT022: {
    terminal_id: 2,
    airport_id: 202,
    airport: "CNF",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 617
  },
  BDT023: {
    terminal_id: 2,
    airport_id: 202,
    airport: "CNF",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 616
  },
  BDT075: {
    terminal_id: 3,
    airport_id: 204,
    airport: "BSB",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 625
  },
  BDT076: {
    terminal_id: 3,
    airport_id: 204,
    airport: "BSB",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 624
  },
  BDT031: {
    terminal_id: 5,
    airport_id: 206,
    airport: "FOR",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 633
  },
  BDT032: {
    terminal_id: 5,
    airport_id: 206,
    airport: "FOR",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 632
  },
  BDT061: {
    terminal_id: 9,
    airport_id: 207,
    airport: "REC",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 637
  },
  BDT062: {
    terminal_id: 9,
    airport_id: 207,
    airport: "REC",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 636
  },
  BDT073: {
    terminal_id: 6,
    airport_id: 209,
    airport: "NAT",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 645
  },
  BDT074: {
    terminal_id: 6,
    airport_id: 209,
    airport: "NAT",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 644

  },
  BRT068: {
    terminal_id: 4,
    airport_id: 203,
    airport: "VCP",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 621
  },
  BRT067: {
    terminal_id: 4,
    airport_id: 203,
    airport: "VCP",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 620
  },
  BRT144: {
    terminal_id: 10,
    airport_id: 205,
    airport: "FLN",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 629
  },
  BRT135: {
    terminal_id: 10,
    airport_id: 205,
    airport: "FLN",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 628
  },
  BRT146: {
    terminal_id: 12,
    airport_id: 233,
    airport: "POA",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 690
  },
  BRT147: {
    terminal_id: 12,
    airport_id: 233,
    airport: "POA",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 692
  },
  BRT004: {
    terminal_id: 13,
    airport_id: 256,
    airport: "SSA",
    terminal: "T1",
    type: "departure",
    pickup_store: false,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 730
  },
  BRT005: {
    terminal_id: 13,
    airport_id: 256,
    airport: "SSA",
    terminal: "T1",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: true,
    quota_enabled: false,
    splashscreen_url: RCServiceLogo,
    store_id: 729
  },
  BFT101: {
    terminal_id: 11,
    airport_id: 194,
    airport: "Uruguaiana",
    terminal: "Loja",
    type: "arrival",
    pickup_store: true,
    reserve_enabled: false,
    quota_enabled: true,
    splashscreen_url: BrasilDutyFreeLogo,
    splashscreen_background_color: "#f30c1c",
    splashscreen_loading_text_color: "white",
    currency_rate: "5.35",
    store_id: 607
  },
  ART513: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART511: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART512: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART514: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART515: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART523: {
    terminal_id: 0,
    airport_id: 0,
    airport: "EZE",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART516: {
    terminal_id: 0,
    airport_id: 0,
    airport: "AEP",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART517: {
    terminal_id: 0,
    airport_id: 0,
    airport: "AEP",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART518: {
    terminal_id: 0,
    airport_id: 0,
    airport: "COR",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART519: {
    terminal_id: 0,
    airport_id: 0,
    airport: "COR",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART520: {
    terminal_id: 0,
    airport_id: 0,
    airport: "MDZ",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART521: {
    terminal_id: 0,
    airport_id: 0,
    airport: "MDZ",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART522: {
    terminal_id: 0,
    airport_id: 0,
    airport: "BRC",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART524: {
    terminal_id: 0,
    airport_id: 0,
    airport: "ROS",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ART525: {
    terminal_id: 0,
    airport_id: 0,
    airport: "ROS",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ECT001: {
    terminal_id: 0,
    airport_id: 0,
    airport: "NULL",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ECT002: {
    terminal_id: 0,
    airport_id: 0,
    airport: "NULL",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  ECT004: {
    terminal_id: 0,
    airport_id: 0,
    airport: "NULL",
    terminal: "T0",
    type: "arrival",
    pickup_store: false,
    reserve_enabled: false,
    quota_enabled: false,
    brl_value_enabled: false,
    splashscreen_url: DufryIcon
  },
  default: {
    reserve_enabled: false,
    quota_enabled: false,
    splashscreen_url: BrasilDutyFreeLogo,
    splashscreen_background_color: "#f30c1c",
    splashscreen_loading_text_color: "white"
  }
};

export const isAddToCartAvailable = async (store) => {
  if (store in airportStores) {
    return airportStores[store]["type"] === "arrival";
  }
  return false;
};

export const validateCart = async (store, items) => {
  try {
    const airport = airportStores[store]["airport"];
    const terminal_id = airportStores[store]["terminal_id"];
    const now = moment().format("YYYY-MM-DD")

    if (airport.length === 0)
      throw new Error(`Can't find airport attached to the store ${store}`);

    const endpoint = `/${airport}/cart/validate`;
    const options = {
      params: {
        terminal_id: terminal_id,
        pickup_date: now,
      }
    }
    const rawData = items.map(({ sku, amount, price, specialPrice }) => {
      const rowTotal = (specialPrice || price) * amount;
      return {
        sku,
        qty_ordered: amount,
        row_total: rowTotal,
      };
    });

    if (rawData.length <= 0)
      throw new Error("Can't get quota without products.");

    const data = await _makeRequest(endpoint, 'POST', rawData, options);

    if (Object.keys(data).includes('detail'))
      throw new Error(data['detail']);

    return data;
  } catch (error) {
    return { detail: error.message };
  }
};

const validateOrderData = (data) => {
  const pickupDate = moment(data["pickup_date"]);

  if (!pickupDate.isValid()) return false;

  if (!Object.keys(airportStores).includes(data["airport_store"])) return false;

  if (!validateEmail(data["email"])) return false;

  if (data["firstname"].length === 0) return false;

  if (data["lastname"].length === 0) return false;

  if (data["phone"].length === 0) return false;

  if (!isValidPhoneNumber(data["phone"])) return false;

  if (data["travel_document"].length === 0) return false;

  return true;
};

export const createOrder = async (orderData) => {
  try {
    if (!validateOrderData(orderData))
      throw new Error("Dados inválidos. Por favor verifique e tente novamente.")

    //Find order with the same data for the same day

    const pickupDate = moment(orderData["pickup_date"]);
    const store = orderData["airport_store"];
    const airportData = airportStores[store];
    const airport = airportData["airport"];

    const endpoint = `/${airport}/orders`;

    const url = window.location.href;

    let comments = `Pedido criado no WebAR, link: ${url}`

    if (orderData["home_delivery"] === true) {
      const address_json = {
        "address":{
           "street": orderData["address"],
           "number": orderData["address_number"],
           "complement": orderData["address_complement"],
           "city": orderData["address_city"],
           "state": orderData["address_uf"],
           "country": "Brasil",
           "cpf": orderData["user_cpf"], 
        }
      }
      const json = JSON.stringify(address_json)
      const address_base64 = btoa(json);
      comments += `. Entrega: ${address_base64}`;
    }

    const rawData = {
      firstname: orderData["firstname"],
      lastname: orderData["lastname"],
      email: orderData["email"],
      phone_number: orderData["phone"],
      travel_type:
        airportData["type"].toLowerCase() === "departure"
          ? "outbound"
          : "inbound",
      travel_document: orderData["travel_document"],
      travel_document_type: orderData["travel_document_type"],
      channel: "app",
      pickup_date: pickupDate.format("YYYY-MM-DD HH:mm:ss"),
      comment: comments,
      terminal_id: airportData["terminal_id"],
      store_id: airportData["store_id"],
    };

    const data = await _makeRequest(endpoint, 'POST', rawData);

    if (Object.keys(data).includes('detail'))
      throw new Error(data['detail']);

    if (
      (Object.keys(data).includes('success') && !data['success']) ||
      !Object.keys(data).includes('success')
    ) {
      throw new Error("Erro ao criar reserva");
    }

    return {
      id: data['data']['id']
    }
  } catch (error) {
    return { detail: error.message };
  }
};

export const addItemsInOrder = async (orderId, items) => {
  try {
    if (!orderId) throw new Error("Order ID is empty.");

    const endpoint = `/orders/${orderId}/items`;

    const rawData = {
      items: items.map(({ sku, amount, salesmanCode }) => ({
        sku: sku,
        qty: amount,
        comission: salesmanCode,
      })),
    };

    if (rawData["items"].length === 0)
      throw new Error("Can't add items with no data.");

    const data = await _makeRequest(endpoint, 'POST', rawData);

    if (Object.keys(data).includes('detail'))
      throw new Error("Can't add items with this data.");

    if (
      (
        Object.keys(data).includes('success') && !data['success'] &&
        Object.keys(data).includes('message')
      ) ||
      (
        !Object.keys(data).includes('success') &&
        Object.keys(data).includes('message')
      )
    )
      throw new Error(data["message"]);

    return {
      success: true,
    };
  } catch (error) {
    return { detail: error.message };
  }
};

const getProductInfoBySapCode = async (store, sapCode) => {
  try {
    const airport = airportStores[store]["airport"];

    if (airport.length === 0)
      throw new Error(`Can't find airport attached to the store ${store}`);

    const endpoint = `/${airport}/product/info/sap/${sapCode}`;

    const data = await _makeRequest(endpoint, "GET");

    if (Object.keys(data).includes('detail'))
      throw new Error(data['detail']);

    if (data.length <= 1) throw new Error("Product info not found.");

    return { sku: data[1]['sku'] };
  } catch (error) {
    return { detail: error.message };
  }
}

const getSkuByEans = async (store, item) => {
  let sku = null;
  const eans = item.eans;

  for (let i = 0; i < eans.length; i++) {
    if (sku !== null)
      break;

    const items = [{
      ...item,
      sku: eans[i]
    }]

    const data = await validateCart(store, items)

    if (Object.keys(data).includes('detail'))
      continue;

    if (Object.keys(data).includes('message'))
      continue;

    sku = eans[i]
    break;
  }

  return sku;
}

const getSkuBySapCode = async (store, sapCode) => {
  let sku = null;

  const data = await getProductInfoBySapCode(store, sapCode);

  if (!Object.keys(data).includes('detail'))
    sku = data['sku'];

  return sku;
}

export const setSkuItems = async (store, items, dispatchSetSkuItemAction) => {
  const notFoundItems = await Promise.all(items.map(async (item) => {
    let skuCode = item.sku;

    if (skuCode === null) {
      if (item.sapCode === null) {
        skuCode = await getSkuByEans(store, item);
      } else {
        skuCode = await getSkuBySapCode(store, item.sapCode);
      }
    }

    if (skuCode === null)
      return {
        gammaCode: item.gammaCode,
        name: item.name
      };

    item.sku = skuCode;
    dispatchSetSkuItemAction(item.gammaCode, skuCode)
    return null;
  }));

  const cleanedList = notFoundItems.filter(i => i);
  return cleanedList;
}