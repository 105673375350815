export const get_env = (key, default_value) => {
    const ENV_DATA = process.env

    if (Object.keys(ENV_DATA).includes(key) && ENV_DATA[key].length > 0)
        return ENV_DATA[key]

    return default_value
}


export const get_env_percent = (key, default_value) => {
    const ENV_DATA = process.env

    if (Object.keys(ENV_DATA).includes(key) && ENV_DATA[key].length > 0)
        return parseFloat(ENV_DATA[key] / 100)

    return  parseFloat(default_value / 100)
}