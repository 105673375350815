import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

//Assets
import { getStoreFromUrl } from "../../helpers/http";
import { airportStores } from "../../services/order";

const SplashScreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor || '#FFF'};
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 30px;

  img {
    height: auto;
    max-width: 80%;
    padding: 50px 0;
  }

  h6 {
    color: ${(props) => props.loadingTextColor || 'red'};
    padding-bottom: 30px;
    font-weight: bold;
  }

  .instructions {

    p {
      font-weight: 400;
    }
  }

  @media (max-width: 767px) {
    .checkout-logo {
        img {
            width: 80%;
        }
    } 
}

`;

const SplashScreen = (props) => {
  const { t, i18n } = useTranslation();
  const timeout = 8000;
  let store = getStoreFromUrl();
  const findStore = airportStores[store]
  const storeProps = {};
  let splashscreenUrl
  let splashscreenBackgroundColor
  let splashscreenLoadingTextColor
  let reserveEnabled
  let instructions

  if (findStore === undefined) {
    store = "default";
  } 
  reserveEnabled = airportStores[store]["reserve_enabled"];
  splashscreenBackgroundColor = airportStores[store]["splashscreen_background_color"];
  splashscreenLoadingTextColor = airportStores[store]["splashscreen_loading_text_color"];
  storeProps['backgroundColor'] = splashscreenBackgroundColor;
  storeProps['loadingTextColor'] = splashscreenLoadingTextColor;
  splashscreenUrl = airportStores[store]["splashscreen_url"];
  
  const setIsLoading = useCallback(
    (isLoading) => props.setIsLoading(isLoading),
    [props]
  );


  let SplashScreenLogo = (
    <img src={splashscreenUrl} alt="Dufry" />
  );

  if(reserveEnabled === true){
    instructions = (
    <div className="instructions">
      <h5><b>Instruções de uso:</b></h5>
      <p>1 - Conceda o acesso à câmera quando solicitado.</p>
      <p>2 - Aponte a câmera para o código de barras do produto.</p>
      <p>3 - Após incluir todos os produtos que deseja no carrinho, finalize o pedido.</p>
    </div>
  );
  }else{
    instructions = (
      <React.Fragment>
      </React.Fragment>
    );
  }


  useEffect(() => {
    setTimeout(() => setIsLoading(false), timeout);
  }, [setIsLoading, timeout]);


  return (
    <SplashScreenWrapper {...storeProps}>
        {SplashScreenLogo}
        <h6><b>{t('Iniciando aplicação...')}</b></h6>
        {instructions}
    </SplashScreenWrapper>
  );
};

export default SplashScreen;
