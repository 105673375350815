import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

//Hooks
import useWindowDimensions from "../../hooks/use-window-dimensions";

//Components
import BoxCartItem from "../UI/Cart/BoxCartItem";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import DialogWrapper from "../UI/DialogBox/DialogWrapper";
import { decryptText } from "../../helpers/string";
import { getStoreFromUrl } from "../../helpers/http";

//Icons
import { ReactComponent as IconLink } from "../../assets/images/icons/icon-link.svg";

const HeaderWrapper = styled.div`
  padding: 1.3rem 0.8rem;

  .greetings {
    padding-bottom: 1rem;
  }

  .text-order-number {
    padding-bottom: 0.5rem;
    font-size: 18px;
    font-weight: 700;
  }

  .second-text > p {
    padding: 0.5rem 0.7rem !important;
  }

  .order-number {
    padding-top: 5px;
    padding-bottom: 10px;
    border: 2px solid #670E0E;
    width: auto;
    margin: 0 auto;
  }

  .order-number a {
    color: #670E0E;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
  }
  .second-text a, .second-text button {
    width: 258px;
    font-size: 16px;
  }
  .second-text p {
    font-size: 14px;
    line-height: 24px;
  }

  .text-small {
    font-size: 12px;
    margin-bottom: 20px!important;
  }
`;

const Confirmation = (props) => {
  const { width, height } = useWindowDimensions();
  
  const linkOrder = () => {
    window.gtag("event", "order_status", {});
    window.open(
      `https://guarulhos.shopdutyfree.com/pt/status-da-reserva?pedido=${props.orderId}`,
      '_blank'
    );
  };

  const openSurvey = () => {
    window.gtag("event", "survey", {});
    window.open(
      'https://dufrycx.questionpro.com/webar',
      '_blank'
    );
  }

  const backToStart = () => {
    window.gtag("event", "back_to_start", {});
    props.onClose();
  }

  const encryptedSelectedStore = localStorage.getItem('selected-store');
  const selectedStore = decryptText(encryptedSelectedStore);
  const storeFromUrl = getStoreFromUrl();
  const equalStores = selectedStore === storeFromUrl

  return (
    <DialogWrapper>
      <DialogCard hiddenCloseButton={true}>
        <DialogCardBody>
          <BoxCartItem width={width} height={height}>
            <HeaderWrapper>
              <Row className="text-center">
                <h3 className="greetings">
                  Obrigado por usar o Serviço de Reservas.
                </h3>
                <h6 className="text-order-number">Número do Pedido</h6>
                <h4 className="order-number">
                  <a href="#" onClick={()=>{linkOrder()}}>
                    {props.orderId}
                    <IconLink className="ms-2" width={18} height={18}/>
                  </a>
                </h4>
                <p className="text-small"><small>Clique para acompanhar a confirmação do seu pedido</small></p>
              </Row>
              <Row className="second-text">
                <Col className="p-0">
                {!equalStores && (
                  <div>
                    <p className="mb-3">
                    A disponibilidade dos produtos para a data selecionada ainda será confirmada. Acompanhe seu pedido através do link enviado para o seu e-mail e WhastApp.
                    </p>
                    {/* <p>
                      Você receberá atualizações por e-mail e WhatsApp. Acompanhe também através do link da página de <a href={linkOrder} target="_NEW">status do pedido</a>.
                    </p> */}
                    <p>
                      Como foi sua experiência? Participe da nossa pesquisa e nos ajude a melhorar o nosso serviço, leva menos de 2 minutos!
                    </p>
                    {/* <p>
                      <a href="https://sso.dufry.com/?source=webar&campaign=selfreserve" target="_NEW">Clique aqui</a> e resgate participando do <strong>Red By Dufry</strong>.
                    </p> */}

                    <div className="text-center mt-4">
                      <a href="#" onClick={()=>{openSurvey()}} className="btn btn-primary mb-md-0 mb-4 px-2 mx-md-3 mx-0" rel="noreferrer">                      
                        Quero participar da pesquisa
                      </a>

                      <button onClick={()=>{backToStart()}} className="btn btn-outline-secondary">
                        Voltar para início
                      </button>
                    </div>

                    
                  </div>)}
                  {/* {equalStores && (
                  <div>
                    <p>
                      Por favor leve os produtos ao caixa junto com o voucher promocional para realizar sua compra com o desconto exclusivo.
                    </p>
                  </div>)} */}
                  </Col>
              </Row>
            </HeaderWrapper>

            {/* {props.items.map((item) => (
              <CartItem
                key={item.gammaCode}
                product={item}
              >
                <CartItemControls 
                  amount={item.amount} 
                  price={item.price} 
                  specialPrice={item.specialPrice}
                  currency={item.currency} />
              </CartItem>
            ))}
            {props.items.length > 0 && <CartItemSummary items={props.items} />} */}
          </BoxCartItem>
        </DialogCardBody>
      </DialogCard>
    </DialogWrapper>
  );
};

export default Confirmation;
