import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";

// Flags
import usFlagSvg from "../../helpers/flags/us";
import brasilFlagSvg from "../../helpers/flags/br";
import { get_env_percent } from "../../helpers/env";

const Wrapper = styled.div`
  .row > * {
    width: auto;
  }
`;

const SHOW_RC_DISCOUNT = true; // 15% discount on R&C
const PERCENT_DISCOUNT = get_env_percent('REACT_APP_DISCOUNT_VALUE', '')

const DialogPriceWrapper = (props) => {  
  const brl_value_enabled = props.brl_value_enabled;

  const hasSpecialOffer = (() => {    
    if (!props.hasStore) return false;

    if (SHOW_RC_DISCOUNT && props.reserveEnabled) return true;

    return props.storeItem.special_price != null ? true : false;
  })();

  const currencyFromStoreItem = props.storeItem.currency;

  const getPriceConverted = (value, convertTo) => {
    let i = props.currencies.findIndex((x) => x.currency_to === "BRL");
    const rate = props.currencies[i].rate;
    if (convertTo === "USD") return value / rate;
    else return value * rate;
  };

  const getUsdPrice = () => {
    try {
      const itemData = props.storeItem;
      const price = itemData.price;
      const currency = itemData.currency;

      if (currency === "USD") return price;
      else return getPriceConverted(price, "USD");
    } catch (error) {
      return 0;
    }
  };

  const getPtbPrice = () => {
    try {
      const itemData = props.storeItem;
      const price = itemData.price;
      const currency = itemData.currency;

      if (currency === "BRL") return price;
      else return getPriceConverted(price, "BRL");
    } catch (error) {
      return 0;
    }
  };

  const specialValue = (currency) => {    
    const price = getPriceWithDiscount();    

    if (currency === "USD") return price;
    else {
      const priceBr = getPriceConverted(price, "BRL");
      props.changePriceBr(priceBr);
      return priceBr;
    }
  };

  const getPriceWithDiscount = () => {    
    const itemData = props.storeItem;
          
    let discount20 = 0;
    let priceWith20Discount = itemData.price;

    if (SHOW_RC_DISCOUNT && props.reserveEnabled && itemData.categoryCode !== "010") { // Avoid tobacco discount
      discount20 = itemData.price * PERCENT_DISCOUNT;
      priceWith20Discount = itemData.price - discount20;
      priceWith20Discount = priceWith20Discount < 0 ? 0 : priceWith20Discount;
    }
    
    if (itemData.special_price > 0 && itemData.special_price < priceWith20Discount) {
      priceWith20Discount = itemData.special_price;
      discount20 = 0;
    }

    return priceWith20Discount;
  };

  return (
    <Wrapper>
      <div className="row align-items-center current-price pt-1">
        {usFlagSvg}
        {hasSpecialOffer && (
          <p>
            <span className="text-line-through before-price">
              <NumberFormat
                value={
                  currencyFromStoreItem === "USD"
                    ? getUsdPrice()
                    : getPtbPrice()
                }
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={currencyFromStoreItem === "USD" ? "US$ " : "R$ "}
              />
            </span>
            <NumberFormat
              value={specialValue("USD")}
              displayType={"text"}
              decimalSeparator={","}
              thousandSeparator={"."}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={"US$ "}
            />
          </p>
        )}
        {!hasSpecialOffer && (
          <p>
            <NumberFormat
              value={getUsdPrice()}
              displayType={"text"}
              decimalSeparator={","}
              thousandSeparator={"."}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={" US$ "}
            />
          </p>
        )}
        <sup>*</sup>
      </div>
      
      {brl_value_enabled && (
        <div className="row align-items-center current-price">
          {brasilFlagSvg}
          {hasSpecialOffer && (
            <p>
              <NumberFormat
                value={specialValue("BRL")}
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"R$ "}
              />
            </p>
          )}
          {!hasSpecialOffer && (
            <p>
              <NumberFormat
                value={getPtbPrice()}
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"R$ "}
              />
            </p>
          )}
          <sup>*</sup>
        </div>
      )}
      </Wrapper>
  );
};

export default DialogPriceWrapper;
