import React, { useReducer } from "react";

import CartContext from "./cartContext";
import cartReducer, { initialState } from "./reducer/cart";
import { addCartItemAction, removeCartItemAction, cancelCartItemAction, setSkuCartItemAction, loadCartByLocalStorageAction } from "./actions/cart";

const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(cartReducer, initialState);

  const addItemCartHandler = (item) =>
    dispatchCartAction(addCartItemAction(item));

  const removeItemCartHandler = (gammaCode) =>
    dispatchCartAction(removeCartItemAction(gammaCode));

  const cancelItemCartHandler = (gammaCode) =>
    dispatchCartAction(cancelCartItemAction(gammaCode));

  const setSkuItemCartHandler = (gammaCode, sku) => dispatchCartAction(setSkuCartItemAction(gammaCode, sku));
  
  const clearCartHandler = () => dispatchCartAction({
    type: 'CLEAR_CART',
    payload: {}
  })

  const loadCartByLocalStorageHandler = () => dispatchCartAction(loadCartByLocalStorageAction());

  const cartContext = {
    items: cartState.items,
    addItem: addItemCartHandler,
    removeItem: removeItemCartHandler,
    cancelItem: cancelItemCartHandler,
    setSkuItem: setSkuItemCartHandler,
    clearCart: clearCartHandler,
    loadCartByLocalStorage: loadCartByLocalStorageHandler
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;