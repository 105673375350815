import { cpf } from "cpf-cnpj-validator";
import { get_env } from "../helpers/env";
import { badRequest, serverErrorRequest, successRequest } from '../helpers/http'
import axios from "axios";

const API = axios.create({
  baseURL: get_env('REACT_APP_BASE_URL_ORDER_API', '/local-api'),
  headers: {
    "content-type": "application/json"
  }
})

const validateCpf = (cpfData) => {
  if (cpfData.length === 0) throw new Error("CPF is empty");

  if (cpfData.length < 11 || cpfData.length > 11)
    throw new Error("CPF is invalid");

  if (!cpf.isValid(cpfData)) throw new Error("CPF is invalid");

  return true;
};

export const getTraveler = async (cpfData) => {
  const cpfFormatted = cpfData.replace(/\D/g, "");

  try {
    if (validateCpf(cpfFormatted)) {
      const res = await API.get(`/traveler/${cpfFormatted}`);
      return res;
    }

    return serverErrorRequest("Try to process request without success");
  } catch (error) {
    return badRequest(error.message);
  }
};

export const getQuotaTraveler = async (cpfData, birthdateData) => {

  try {
    const cpfFormatted = cpfData.replace(/\D/g, "");
    const birthdateFormatted = new Date(birthdateData);

    if (validateCpf(cpfFormatted)) {

      const res = await API.get(`/quota?cpf=${cpfFormatted}&birthdate=${birthdateFormatted.toISOString().substr(0, 10)}`);

      return res;
    }

    return serverErrorRequest("Try to process request without success");
  } catch (error) {
    return badRequest(error.message);
  }
};

export const getQuotaTravelerByCpf = async (cpf) => {
  try {
    const cpfFormatted = cpf.replace(/\D/g, "");
    const resTravelerData = await getTraveler(cpfFormatted);

    const resQuotaTraveler = await getQuotaTraveler(
        cpfFormatted,
        resTravelerData.data.dataNascimento
    );

    return resQuotaTraveler;
  } catch (error) {
    return badRequest(error.message);
  }
};
