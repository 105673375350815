import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Alert, Form } from "react-bootstrap";
import styled from "styled-components";
import { useState } from "react";

// Components
import CartItem from "./CartItem/CartItem";
import CartItemControls from "./CartItem/Controls";
import CartItemSummary from "./CartItem/CartItemSummary";

// UI
import DialogWrapper from "../UI/DialogBox/DialogWrapper";
import DialogTagWrapper from "../UI/DialogBox/DialogTagWrapper";
import DialogCard from "../UI/DialogBox/DialogCard";
import DialogCardBody from "../UI/DialogBox/DialogCardBody";
import StyledForm from "../UI/Forms/StyledForm";
import FeedbackFailed from "../UI/Forms/FeedbackFailed";
import BoxCartItem from "../UI/Cart/BoxCartItem";
import DialogButton from "../UI/Buttons/DialogButton";

// Hooks
import useWindowDimensions from "../../hooks/use-window-dimensions";
import useInput from "../../hooks/use-input";

// Services
import { airportStores } from "../../services/order";
import { decryptText, encryptText } from "../../helpers/string";
import { getPickupStoresFromUrl } from "../../helpers/http";

// Assets
import { ReactComponent as BagIcon } from "../../assets/images/icons/bag.svg";


const CartWithoutItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;

  span.highlight {
    color: #e33338;
  }
`;

const Cart = (props) => {
  const { width, height } = useWindowDimensions();
  const pickupStoreKey = "pickup-store";
  const pickupAirportRef = React.createRef();
  const buttonProps = {};
  const ReserveButton = DialogButton;  
  const urlPickupStores = getPickupStoresFromUrl();

  const pickupAirportValidation = (value) => value.length > 0;
  const {
    value: pickupAirportStore,
    setInput: setPickupStore,
    isInvalid: isInvalidPickupAirport,
    onChangeHandler: onChangePickupAirportHandler,
    onBlurHandler: onBlurPickupAirportHandler,
    onReset: onResetPickupAirportInput,
    onValidate: onValidatePickupAirportInput,
  } = useInput(pickupAirportValidation, "");

  const [defaultStore, setDefaultStore] = useState('');

  const getSelectedStore = () => {
    let store = localStorage.getItem('default-store');
    if (store){
      setDefaultStore(store);
    }
  };

  const setSelectedStore = (value) => {
    if(value){
      localStorage.setItem('default-store', value);
      localStorage.setItem(pickupStoreKey, encryptText(value));
      var eventStoreSelection = window.dataLayer?.findIndex(x => x[1] === "store_selection");
      if (eventStoreSelection < 0)  {
          window.gtag("event", "store_selection", {});
      }
    }
  };

  useEffect(() => {
    setSelectedStore(pickupAirportStore);
    getSelectedStore();

    if (props.items.length > 0) {
      const pickupStoreLocalStorage = localStorage.getItem(pickupStoreKey);

      if (pickupStoreLocalStorage !== null && pickupAirportStore.length === 0) {
        const decryptedPickupStore = decryptText(pickupStoreLocalStorage);

        if (pickupAirportRef && pickupAirportRef.current) {
          pickupAirportRef.current.value = decryptedPickupStore;
        }

        setPickupStore(decryptedPickupStore);
      }
    }
  }, [pickupAirportRef, pickupAirportStore, setPickupStore, props.items]);

  const submitForm = (event) => {
    event.preventDefault();
    return false;
  };

  const goToCheckout = () => {
    if (!onValidatePickupAirportInput(pickupAirportStore)) {
      localStorage.removeItem(pickupStoreKey);
      pickupAirportRef.current.focus();
      return false;
    }

    localStorage.setItem(pickupStoreKey, encryptText(pickupAirportStore));
    localStorage.setItem('selected-store', encryptText(pickupAirportStore));

    onResetPickupAirportInput();
    props.onButtonAction(pickupAirportStore);
  };

  let pickupStores = Object.keys(airportStores).filter(
    (store) => airportStores[store]["pickup_store"] === true
  );

  if (urlPickupStores){
    pickupStores = Object.keys(airportStores).filter(
      (store) => urlPickupStores.indexOf(store) !== -1
    );
  }

  pickupStores.sort((a, b) => ((airportStores[a]["airport"]+airportStores[a]["terminal"]) > (airportStores[b]["airport"]+airportStores[b]["terminal"]) ? 1 : -1));

  if (pickupStores.length === 1){
    setSelectedStore(pickupStores[0]);
    if (!pickupAirportStore){
      setPickupStore(pickupStores[0]);
    }
  }

  if (props.processingValidation) {
    buttonProps["disabled"] = true;
  }

  if (props.extraLine) {
    buttonProps["extraLine"] = true;
  }

  return ReactDOM.createPortal(
    <DialogWrapper>
      <DialogCard hiddenCloseButton="true" onClose={props.onCloseCart}>
        <DialogCardBody>
          <BoxCartItem width={width} height={height}>
            {props.items.length > 0 && (
              <StyledForm
                onSubmit={submitForm}                
              >
                <div className="mb-3">
                  <Form.Group controlId="floatingInputAirportPickup">
                    <p><b>Carrinho</b></p>
                    <Form.Label>Loja da retirada</Form.Label>
                    <Form.Select
                      onChange={onChangePickupAirportHandler}
                      onBlur={onBlurPickupAirportHandler}
                      isInvalid={isInvalidPickupAirport}
                      ref={pickupAirportRef}
                      value={defaultStore}
                    >
                      <option value="">--Por favor selecione--</option>
                      {pickupStores.map((store) => {
                        const data = airportStores[store];
                        const description = `${
                          data["type"].toLowerCase() === "departure"
                            ? "Embarque do Terminal "
                            : "Desembarque do Terminal "
                        } ${data["terminal"].replace('T','')} - ${data["airport"]}`;
                        return (
                          <option key={store} value={store}>
                            {description}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {isInvalidPickupAirport && (
                      <FeedbackFailed>
                        Por favor selecione o aeroporto da retirada do seu
                        pedido.
                      </FeedbackFailed>
                    )}
                  </Form.Group>
                </div>
              </StyledForm>
            )}

            <div className="cart-box">
              {props.items.map((item) => (
                <CartItem
                  key={item.gammaCode}
                  product={item}
                  onCloseItem={props.onCancelItem}
                >
                  <CartItemControls
                    product={item}
                    onAddItem={props.onAddItem}
                    onRemoveItem={props.onRemoveItem}
                  />
                </CartItem>
              ))}
            </div>


            {props.items.length > 0 && (
              <CartItemSummary items={props.items}>
                {props.errorMessage && (
                  <div className="error-message">
                    <Alert variant="danger">{props.errorMessage}</Alert>
                  </div>
                )}

                <DialogTagWrapper>
                  <button className="btn btn-outline-secondary" onClick={props.onCloseCart}>
                      Voltar
                  </button>
                  <ReserveButton
                      className="btn-secondary w-100"
                      onClick={goToCheckout}
                      {...buttonProps}
                    >
                      {props.processingValidation
                        ? "Processando..."
                        : "Fechar Pedido"}
                      <BagIcon />
                  </ReserveButton>
                </DialogTagWrapper>
              </CartItemSummary>
            )}

            {props.items.length === 0 && (
              <CartWithoutItems>
                <h3>Ops! Seu carrinho de compras está vazio.</h3>
                <p>
                  Faça a leitura do código de barras do produto e adicione no
                  carrinho!
                </p>
                <DialogTagWrapper>
                  <button className="btn btn-outline-secondary" onClick={props.onCloseCart}>
                      Voltar
                  </button>              
                </DialogTagWrapper>   
              </CartWithoutItems>                         
            )}
          </BoxCartItem>
        </DialogCardBody>
      </DialogCard>
    </DialogWrapper>,
    document.querySelector("#cart-root")
  );
};

export default Cart;
