import axios from 'axios';

const _makeRequest = async(endpoint, method, data) => {
  try {
    let res;
    const methodFormatted = method.toUpperCase();
    
    if (methodFormatted === 'POST') {
      res = await axios.post(endpoint, data)
    }

    if (methodFormatted === 'GET') {
      res = await axios.get(endpoint, data)
    }

    if (res.status !== 200) {
      throw new Error(res.statusText)
    }

    const resData = await res.data;

    if (Object.keys(resData).includes('detail')) {
      throw new Error(res['detail'])
    }

    return resData;

  } catch (error) {
    return { detail: error.message }
  }
}

export const requestSsoToken = async (code) => {
    const endpoint = '/local-api/sso/token';
    const rawData = {
      'code': code
    };

    const data = await _makeRequest(endpoint, 'POST', rawData);
    if (Object.keys(data).includes('detail')) {
      throw new Error(data['detail'])
    }
    return data;
};

export const requestSsoInfo = async (token) => {
    const endpoint = '/local-api/sso/userinfo';
    const rawData = {
      'token': token
    };

    const data = await _makeRequest(endpoint, 'POST', rawData);
    if (Object.keys(data).includes('detail')) {
      throw new Error(data['detail'])
    }
    return data;
};