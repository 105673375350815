import React, { useReducer } from "react";

// Context
import ManageWindowsContext from "./manageWindowsContext";

// Reducer
import ManageWindowsReducer from "./reducer/manageWindows";

// Actions
import {
  openClosePriceDialog,
  openCloseQuota,
  openCloseCart,
  openCloseCheckout,
  openCloseCheckoutConfirmation,
  openCloseBarcodeInput,
  openCloseAddToCart,
  openCloseAreYouSure
} from "./actions/manageWindows";

const ManageWindowProvider = (props) => {
  const [manageWindowState, dispatchAction] = useReducer(ManageWindowsReducer);

  const openClosePriceDialogAction = (boolValue) =>
    dispatchAction(openClosePriceDialog(boolValue));

  const openCloseQuotaAction = (boolValue) =>
    dispatchAction(openCloseQuota(boolValue));

  const openCloseCartAction = (boolValue) =>
    dispatchAction(openCloseCart(boolValue));

  const openCloseCheckoutAction = (boolValue) =>
  dispatchAction(openCloseCheckout(boolValue));

  const openCloseCheckoutConfirmationAction = (boolValue) =>
  dispatchAction(openCloseCheckoutConfirmation(boolValue));

  const openCloseBarcodeInputAction = (boolValue) =>
  dispatchAction(openCloseBarcodeInput(boolValue));

  const openCloseAddToCartAction = (boolValue) =>
  dispatchAction(openCloseAddToCart(boolValue));

  const openCloseAreYouSureAction = (boolValue) =>
  dispatchAction(openCloseAreYouSure(boolValue));

  const context = {
    ...manageWindowState,
    openClosePriceDialog: openClosePriceDialogAction,
    openCloseQuota: openCloseQuotaAction,
    openCloseCart: openCloseCartAction,
    openCloseCheckout: openCloseCheckoutAction,
    openCloseCheckoutConfirmation: openCloseCheckoutConfirmationAction,
    openCloseBarcodeInput: openCloseBarcodeInputAction,
    openCloseAddToCart: openCloseAddToCartAction,
    openCloseAreYouSure: openCloseAreYouSureAction
  };

  return (
    <ManageWindowsContext.Provider value={context}>
      {props.children}
    </ManageWindowsContext.Provider>
  );
};

export default ManageWindowProvider;
