import CryptoJs from "crypto-js";
require('url');
const nodePath = require('path');

const secretKey = "asdasd9asd8adskda2lna2";

export const encryptText = (data) =>
  CryptoJs.AES.encrypt(JSON.stringify(data), secretKey).toString();

export const decryptText = (encryptedText) => {
  const bytes = CryptoJs.AES.decrypt(encryptedText, secretKey);
  return JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const capitalizeFirstLetter = (word) => {
  try {
    const lowerWord = word.toLowerCase();
    return lowerWord.charAt(0).toUpperCase() + lowerWord.slice(1);
  } catch {
    return "";
  }
}

export const combineHostEndpoint = (host, endpoint = []) => {
  const url = new URL(host);
  url.pathname = nodePath.join(endpoint.join(''));
  return url.toString();
}

export const validateEmail = (value) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}