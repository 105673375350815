import React from "react";

// Components
import CallActionButton from "../CallActionButton";
import FloatButtonWrapper from "../UI/Buttons/FloatButtonWrapper";
import CartButton from "../Cart/CartButton";
import Salesman from "../SalesmanCodeBox/Salesman";

import { isAddToCartAvailable, airportStores } from "../../services/order";

const Sidebar = ({store, manageWindowCtx, cartCtx, salesmanCode}) => {
    const findStore = airportStores[store]
    let reserveEnabled
    let quotaEnabled
    if (findStore === undefined) {
        reserveEnabled = airportStores["default"]["reserve_enabled"];
        quotaEnabled = airportStores["default"]["quota_enabled"];
    } else{
        reserveEnabled = airportStores[store]["reserve_enabled"];
        quotaEnabled = airportStores[store]["quota_enabled"];
    }
    const isCartShowed = store && isAddToCartAvailable(store.toUpperCase());
    const showCheckoutConfirmation = manageWindowCtx.showCheckoutConfirmation;

    const showCart = manageWindowCtx.showCart;
    const displayCartButton = !showCart && reserveEnabled && isCartShowed && !showCheckoutConfirmation;

    const items = cartCtx.items;
    const amount = items.reduce((acc, curItem) => acc + curItem.amount, 0);

    return (
        <>
            <FloatButtonWrapper>
                {displayCartButton && (
                    <CartButton
                        openCart={() => manageWindowCtx.openCloseCart(true)}
                        amount={amount}
                    />
                )}
                {quotaEnabled && <CallActionButton/>}
            </FloatButtonWrapper>
            
            <Salesman salesmanCode={salesmanCode}></Salesman>
        </>
    );
};

export default Sidebar;
