import {
  ADD_ITEM,
  REMOVE_ITEM,
  CANCEL_ITEM,
  SET_SKU_ITEM,
  LOAD_CART_BY_LOCAL_STORAGE
} from "../actions/cart";

import { encryptText, decryptText } from "../../helpers/string"

const cartKey = "cart";

export const initialState = {
  items: [],
};

const getExistingItem = (items, gammaCode) =>
  items.findIndex((v) => v.gammaCode === gammaCode);

const storeCartOnLocalStorage = (items) => {
  if (Object.keys(items).length === 0) {
    localStorage.removeItem(cartKey);
    return;
  }

  localStorage.setItem(cartKey, encryptText(items));
}

const retrieveCartOnLocalStorage = () => {
  const data = localStorage.getItem(cartKey);

  if (data === null) return {}

  const decryptedData = decryptText(data);
  return decryptedData;
}

const cartReducer = (state, { type, payload }) => {
  let existingCartItem, existingItem, updatedItems, updatedItem, gammaCode;

  switch (type) {
    case ADD_ITEM:
      const item = payload;

      existingItem = getExistingItem(state.items, item.gammaCode);
      existingCartItem = state.items[existingItem];

      if (existingCartItem) {
        updatedItem = {
          ...existingCartItem,
          amount: existingCartItem.amount + item.amount,
        };

        updatedItems = [...state.items];
        updatedItems[existingItem] = updatedItem;
      } else {
        updatedItems = state.items.concat(item);
      }

      storeCartOnLocalStorage(updatedItems)

      return {
        ...state,
        items: updatedItems,
      };

    case REMOVE_ITEM:
      gammaCode = payload;
      existingItem = getExistingItem(state.items, gammaCode);
      existingCartItem = state.items[existingItem];

      if (existingCartItem && existingCartItem.amount === 1) {
        return state;
      }

      updatedItems = state.items.filter((item) => item.gammaCode !== gammaCode);

      if (existingCartItem && existingCartItem.amount > 1) {
        updatedItems = [...state.items];
        updatedItems[existingItem] = {
          ...existingCartItem,
          amount: existingCartItem.amount - 1,
        };
      }

      storeCartOnLocalStorage(updatedItems)

      return {
        ...state,
        items: updatedItems,
      };

    case CANCEL_ITEM:
      gammaCode = payload;

      updatedItems = state.items.filter((item) => item.gammaCode !== gammaCode);
      storeCartOnLocalStorage(updatedItems)

      return {
        ...state,
        items: updatedItems,
      };

    case SET_SKU_ITEM:
      gammaCode = payload.gammaCode;
      const sku = payload.sku;

      updatedItems = [...state.items];
      existingItem = getExistingItem(updatedItems, gammaCode);
      existingCartItem = updatedItems[existingItem];

      if (existingCartItem) {
        existingCartItem["sku"] = sku;

        updatedItems[existingItem] = {
          ...existingCartItem,
        };
      }

      storeCartOnLocalStorage(updatedItems)

      return {
        ...state,
        items: updatedItems,
      };

    case LOAD_CART_BY_LOCAL_STORAGE:
      const data = retrieveCartOnLocalStorage();

      if (Object.keys(state.items).length > 0)
        return state;

      return {
        ...state,
        items: data
      }

    default:
      localStorage.removeItem(cartKey);
      return initialState;
  }
};

export default cartReducer;
