import React, { useState } from "react";

//Components
import Cart from "./Cart";

//UI
import OrderBox from "../UI/OrderBox";

//Services
import { setSkuItems, validateCart } from "../../services/order";

const Wrapper = (props) => {
  const [loadValidation, setLoadValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const setNotFoundItemsMessage = (notFoundItems) => {
    if (notFoundItems.length > 0) {
      const elementError = (
        <React.Fragment>
          <p>
            Infelizmente os produtos abaixo não estão disponíveis na loja
            selecionada.
          </p>
          <ul style={{ listStyle: "disc" }}>
            {notFoundItems.map((item) => (
              <li key={item.gammaCode}>{item.name}</li>
            ))}
          </ul>
        </React.Fragment>
      );

      setErrorMessage(elementError);
    }
  };

  const getItemBySku = (items, skuCode) => {
    const findItem = items.filter((item) => item.sku === skuCode);

    if (findItem.length > 0) return findItem[0];

    return {};
  };

  const confirmCart = async (pickup_store) => {
    setLoadValidation(true);
    setErrorMessage("");
    const items = Object.assign([], props.items);
    const notFoundItems = await setSkuItems(
      pickup_store,
      items,
      props.onSetSkuItem
    );

    if (notFoundItems.length > 0) {
      setNotFoundItemsMessage(notFoundItems);
      setLoadValidation(false);
      return false;
    }

    const data = await validateCart(pickup_store, items);

    if (Object.keys(data).includes('detail')) {
      setErrorMessage(`Error: ${data['detail']}`);
      setLoadValidation(false);
      return false;
    }

    if (Object.keys(data).includes("message")) {
      const message = data["message"];
      const skuCodeFromMessage = message.replace("Undefined offset: ", "");
      const itemWithError = getItemBySku(props.items, skuCodeFromMessage);

      if (Object.keys(itemWithError).length > 0) {
        setNotFoundItemsMessage([
          {
            gammaCode: itemWithError.gammaCode,
            name: itemWithError.name,
          },
        ]);
      } else {
        setErrorMessage(message);
      }

      setLoadValidation(false);
      return false;
    }

    if (data && data.length > 0) {
      const quotaErrorMessage = (
        <React.Fragment>
          <p>Cota excedida:</p>
          <ul style={{ marginBottom: 0, listStyle: "disc" }}>
            {data.map((item, id) => (
              <li key={id}>{item.description}</li>
            ))}
          </ul>
        </React.Fragment>
      );

      setErrorMessage(quotaErrorMessage);
      setLoadValidation(false);
      return false;
    }

    setErrorMessage("");
    setLoadValidation(false);
    props.onOpenCheckout();
  };

  return (
    <OrderBox>
      <Cart
        items={props.items}
        onCloseCart={props.onCloseCart}
        onAddItem={props.onAddItem}
        onRemoveItem={props.onRemoveItem}
        onCancelItem={props.onCancelItem}
        onButtonAction={confirmCart}
        processingValidation={loadValidation}
        errorMessage={errorMessage}
        showWaitMoreItems={props.showWaitMoreItems}
        onSalesmanCodeChange={props.onSalesmanCodeChange}
      />
    </OrderBox>
  );
};

export default Wrapper;
