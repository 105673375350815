import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

// Icons
import { BsPlusLg, BsDashLg } from "react-icons/bs";

const Controls = ({ product, onRemoveItem, onAddItem }) => {
  const gammaCode = product.gammaCode;
  const amount = product.amount;

  const addItem = () =>
    onAddItem({
      ...product,
      amount: 1,
    });

  const removeItem = () => onRemoveItem(gammaCode);

  return (
    <div className="controls">
      <ButtonGroup>
        <Button variant="outline-secondary" onClick={removeItem}>
          <BsDashLg />
        </Button>
        <Button variant="outline-secondary" disabled>
          {amount}
        </Button>
        <Button variant="outline-secondary" onClick={addItem}>
          <BsPlusLg />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Controls;
