import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Badge } from "react-bootstrap";

// Icons
import { IconContext } from "react-icons";
import { BsHandbag as CartIcon } from "react-icons/bs";
import CallButton from "../UI/Buttons/CallButton";

const bumpAnimation = keyframes`
    0% {
        transform: scale(1);
    }

    25% {

        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
`;

const CartButtonStyled = styled(CallButton)`
  &.bump {
    animation: ${bumpAnimation} 300ms ease-out;
  }
`;

const BagdeStyled = styled(Badge)`
  position: absolute;
  left: -10px;
`;

const CartButton = (props) => {
  const [showBump, setShowBump] = useState(false);
  const totalAmount = props.amount;
  let classes = "";

  useEffect(() => {
    if (totalAmount > 0) setShowBump(true);

    const bumpTimeout = setTimeout(() => setShowBump(false), 300);

    return () => clearTimeout(bumpTimeout);
  }, [totalAmount]);

  if (showBump) {
    classes = "bump";
  }

  return (
    <div>
      <IconContext.Provider value={{ size: "1.7em" }}>
        <CartButtonStyled
          size="2.9rem"
          variant="outline-light"
          onClick={props.openCart}
          className={classes}
        >
          <CartIcon />
        </CartButtonStyled>
        {props.amount > 0 && (
          <BagdeStyled pill bg="danger" text="light">
            {props.amount}
          </BagdeStyled>
        )}
      </IconContext.Provider>
    </div>
  );
};

export default CartButton;
