const brasilFlagSvg = (
  <svg
    className="brazil-flag"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5689 20.6896H0.431054C0.193017 20.6896 0 20.4966 0 20.2586V4.74139C0 4.50335 0.193017 4.31033 0.431054 4.31033H24.5689C24.807 4.31033 25 4.50335 25 4.74139V20.2586C25 20.4967 24.8069 20.6896 24.5689 20.6896Z"
      fill="#00903B"
    />
    <path
      d="M12.2759 6.602L3.19111 12.1318C2.91518 12.2997 2.91518 12.7002 3.19111 12.8682L12.2759 18.3981C12.4135 18.4819 12.5864 18.4819 12.7241 18.3981L21.8089 12.8682C22.0848 12.7003 22.0848 12.2998 21.8089 12.1319L12.7241 6.602C12.5864 6.51821 12.4135 6.51821 12.2759 6.602Z"
      fill="#FAD500"
    />
    <path
      d="M12.5 15.9483C14.4044 15.9483 15.9482 14.4044 15.9482 12.5C15.9482 10.5956 14.4044 9.05179 12.5 9.05179C10.5956 9.05179 9.05174 10.5956 9.05174 12.5C9.05174 14.4044 10.5956 15.9483 12.5 15.9483Z"
      fill="#032573"
    />
    <path
      d="M9.54105 10.736C9.37835 11.0084 9.25272 11.3044 9.16971 11.6187C11.0813 11.4748 13.9091 12.0096 15.7089 13.756C15.8263 13.4562 15.9039 13.1367 15.933 12.8028C14.0745 11.2387 11.5053 10.6825 9.54105 10.736Z"
      fill="#F5F5F5"
    />
    <path
      d="M12.6428 13.6767L12.7346 13.952L13.0248 13.9543C13.0623 13.9546 13.0779 14.0025 13.0477 14.0248L12.8143 14.1972L12.9018 14.4739C12.9132 14.5097 12.8723 14.5394 12.8418 14.5175L12.6057 14.3488L12.3697 14.5175C12.3391 14.5394 12.2983 14.5097 12.3096 14.4739L12.3972 14.1972L12.1637 14.0248C12.1335 14.0025 12.1491 13.9546 12.1866 13.9543L12.4768 13.952L12.5686 13.6767C12.5805 13.6411 12.6309 13.6411 12.6428 13.6767Z"
      fill="#F5F5F5"
    />
    <path
      d="M13.7707 14.3889L13.8102 14.5074L13.9351 14.5083C13.9512 14.5084 13.958 14.5291 13.9449 14.5387L13.8444 14.6129L13.8821 14.732C13.887 14.7474 13.8694 14.7601 13.8563 14.7507L13.7547 14.6781L13.653 14.7507C13.6399 14.7601 13.6223 14.7474 13.6272 14.732L13.6649 14.6129L13.5644 14.5387C13.5514 14.5291 13.5581 14.5085 13.5742 14.5083L13.6991 14.5074L13.7386 14.3889C13.7438 14.3735 13.7655 14.3735 13.7707 14.3889Z"
      fill="#F5F5F5"
    />
    <path
      d="M12.1552 13.1538L12.1947 13.2722L12.3196 13.2732C12.3357 13.2733 12.3425 13.294 12.3294 13.3036L12.2289 13.3778L12.2666 13.4969C12.2715 13.5123 12.2539 13.525 12.2407 13.5156L12.1391 13.443L12.0375 13.5156C12.0244 13.525 12.0068 13.5123 12.0117 13.4969L12.0493 13.3778L11.9489 13.3036C11.9359 13.294 11.9426 13.2734 11.9587 13.2732L12.0836 13.2722L12.1231 13.1538C12.1283 13.1385 12.15 13.1385 12.1552 13.1538Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.96727 13.0101L10.0068 13.1286L10.1317 13.1296C10.1478 13.1297 10.1546 13.1503 10.1415 13.1599L10.041 13.2341L10.0787 13.3532C10.0836 13.3686 10.066 13.3814 10.0529 13.372L9.95126 13.2994L9.84964 13.372C9.83651 13.3814 9.81893 13.3686 9.82381 13.3532L9.86146 13.2341L9.76097 13.1599C9.74799 13.1504 9.75467 13.1297 9.77084 13.1296L9.89574 13.1286L9.93524 13.0101C9.94046 12.9948 9.96214 12.9948 9.96727 13.0101Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.7975 14.348L11.837 14.4664L11.9619 14.4674C11.9781 14.4675 11.9848 14.4882 11.9718 14.4978L11.8713 14.572L11.909 14.691C11.9138 14.7065 11.8963 14.7192 11.8831 14.7098L11.7815 14.6372L11.6799 14.7098C11.6668 14.7192 11.6492 14.7065 11.6541 14.691L11.6917 14.572L11.5912 14.4978C11.5782 14.4882 11.5849 14.4676 11.6011 14.4674L11.726 14.4664L11.7655 14.348C11.7707 14.3327 11.7925 14.3327 11.7975 14.348Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.7905 12.9275L10.8228 13.0243L10.925 13.0252C10.9382 13.0253 10.9437 13.0422 10.9331 13.05L10.8509 13.1107L10.8817 13.208C10.8857 13.2206 10.8714 13.2311 10.8606 13.2234L10.7775 13.164L10.6944 13.2234C10.6836 13.2311 10.6693 13.2206 10.6732 13.208L10.704 13.1107L10.6219 13.05C10.6113 13.0422 10.6167 13.0253 10.63 13.0252L10.7321 13.0243L10.7644 12.9275C10.7686 12.9149 10.7863 12.9149 10.7905 12.9275Z"
      fill="#F5F5F5"
    />
    <path
      d="M13.8583 10.9274L13.8988 11.0486L14.0265 11.0496C14.043 11.0497 14.0499 11.0708 14.0365 11.0806L13.9338 11.1565L13.9723 11.2784C13.9774 11.2941 13.9593 11.3072 13.9459 11.2975L13.842 11.2232L13.738 11.2975C13.7245 11.3072 13.7066 11.2941 13.7116 11.2784L13.7501 11.1565L13.6474 11.0806C13.6341 11.0708 13.6409 11.0497 13.6574 11.0496L13.7852 11.0486L13.8256 10.9274C13.8309 10.9117 13.8531 10.9117 13.8583 10.9274Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.108 12.3348L10.1403 12.4317L10.2425 12.4325C10.2557 12.4326 10.2612 12.4495 10.2506 12.4573L10.1684 12.518L10.1992 12.6154C10.2032 12.628 10.1889 12.6384 10.1781 12.6307L10.095 12.5713L10.0119 12.6307C10.0011 12.6384 9.98676 12.628 9.99071 12.6154L10.0215 12.518L9.93934 12.4573C9.92875 12.4495 9.93417 12.4326 9.94745 12.4325L10.0496 12.4317L10.0819 12.3348C10.0861 12.3222 10.1038 12.3222 10.108 12.3348Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.6011 13.7002L10.6501 13.847L10.8048 13.8482C10.8248 13.8483 10.8331 13.8738 10.817 13.8858L10.6925 13.9777L10.7392 14.1251C10.7452 14.1442 10.7235 14.16 10.7072 14.1484L10.5813 14.0584L10.4554 14.1484C10.4392 14.16 10.4174 14.1442 10.4235 14.1251L10.4701 13.9777L10.3457 13.8858C10.3296 13.8738 10.3379 13.8483 10.3579 13.8482L10.5126 13.847L10.5615 13.7002C10.568 13.6813 10.5948 13.6813 10.6011 13.7002Z"
      fill="#F5F5F5"
    />
    <path
      d="M14.3995 14.4197L14.4303 14.512L14.5276 14.5127C14.5402 14.5128 14.5454 14.5289 14.5353 14.5364L14.457 14.5942L14.4864 14.6869C14.4902 14.699 14.4765 14.7089 14.4662 14.7015L14.3871 14.645L14.3079 14.7015C14.2976 14.7089 14.284 14.699 14.2877 14.6869L14.3171 14.5942L14.2388 14.5364C14.2287 14.5289 14.2339 14.5128 14.2465 14.5127L14.3438 14.512L14.3746 14.4197C14.3786 14.4077 14.3956 14.4077 14.3995 14.4197Z"
      fill="#F5F5F5"
    />
    <path
      d="M14.7401 13.7151L14.7796 13.8335L14.9045 13.8345C14.9206 13.8346 14.9274 13.8553 14.9143 13.8649L14.8138 13.939L14.8515 14.0581C14.8564 14.0736 14.8388 14.0863 14.8257 14.0769L14.724 14.0043L14.6224 14.0769C14.6093 14.0863 14.5917 14.0736 14.5966 14.0581L14.6342 13.939L14.5338 13.8649C14.5208 13.8553 14.5275 13.8347 14.5436 13.8345L14.6685 13.8335L14.708 13.7151C14.7132 13.6997 14.7349 13.6997 14.7401 13.7151Z"
      fill="#F5F5F5"
    />
  </svg>
);

export default brasilFlagSvg;